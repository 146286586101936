import { Layout } from '@pixi/layout';
import { NineSlicePlane, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { uiAlignCenterX } from '../../../../lib/pixi/uiTools';
import { PopupOptions, PopupScreen } from '../../screens/PopupScreen';
import { TextSliceButton } from '../buttons/TextSliceButton';
import { UpDownButton } from '../buttons/UpDownButton';
import { BasicText } from '../text/BasicText';

// types
//-----------------------------------------------------------------------------
export type BasicPopupOptions = {
    header?: string;
    okButton?: string;
    onOk?: BasicAsyncHandler;
} & PopupOptions;

// constants
//-----------------------------------------------------------------------------
const manifest = {
    // bg: 'frame.generic.header.blue.png',
    bg: 'frame.popup.default.png',
    up: 'button.blue.png',
    close: 'button.close2.png',
};

/*
    basic popup. will evolve/split as recommended by art team.
*/
export abstract class BasicPopup extends PopupScreen {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onOk?: BasicAsyncHandler;
    // scene
    protected main: Layout;

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets(Object.values(manifest));
    }

    public override async spawning(options: BasicPopupOptions) {
        super.spawning(options);

        // set fields
        this.onOk = options.onOk;

        // scene
        this.base.addContent({
            // background
            bg: {
                // content: new NineSlicePlane(Texture.from(manifest.bg), 70, 94, 70, 85).props({
                content: new NineSlicePlane(Texture.from(manifest.bg), 65, 65, 65, 65).props({
                    width: options.width,
                    height: options.height,
                }),
                styles: {
                    width: options.width,
                    height: options.height,
                },
            },
            header: options.header
                ? {
                      content: new BasicText({
                          text: options.header,
                          style: {
                              fill: 'fff4e6',
                              strokeThickness: 4,
                              fontSize: 30,
                              fontWeight: 'bold',
                              lineJoin: 'round',
                          },
                      }),
                      styles: {
                          marginTop: 30,
                          position: 'centerTop',
                      },
                  }
                : null,
            main: {
                content: (this.main = new Layout({
                    styles: {
                        width: '100%',
                        height: '100%',
                    },
                })),
                styles: {
                    width: options.width - 40,
                    height: options.width - 40,
                    position: 'center',
                },
            },
        });

        if (this.onClose) {
            const button = new UpDownButton({
                up: manifest.close,
                down: manifest.close,
                animate: true,
            });
            button.props({
                pivot: { x: button.width * 0.5, y: button.height * 0.5 },
                x: 68,
                y: 24,
                onPress: async () => this.onClose?.(),
            });
            this.base.addContent({
                // close button
                close: {
                    content: button,
                    styles: {
                        marginTop: 25,
                        marginRight: -55,
                        position: 'topRight',
                    },
                },
            });
        }

        // ok button
        if (this.onOk) {
            const okButton = new TextSliceButton({
                text: options.okButton ?? '[buttonOk]',
                animate: true,
                offsetY: -3,
                slice: {
                    sliceUp: manifest.up,
                    sliceDown: manifest.up,
                    width: 190,
                    height: 69,
                    left: 45,
                    top: 0,
                    right: 45,
                    bottom: 0,
                },
                style: {
                    fill: 'fff4e6',
                    strokeThickness: 4,
                    fontSize: 28,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    fontStyle: 'italic',
                },
            });
            okButton.props({
                y: this.base.height - 90,
                pivot: { x: okButton.width * 0.5, y: okButton.height * 0.5 },
                onPress: async () => this.onOk?.(),
            });
            uiAlignCenterX(this.base, okButton); // Align with pivot to enable scale animation from center
            this.base.addChild(okButton);
        }
    }
}
