import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { BasicText } from '../text/BasicText';
import { BasicPopup } from './BasicPopup';

// types
//-----------------------------------------------------------------------------
export type InfoPopupOptions = {
    title: string;
    infoText: string;
    okButton?: string;
    onOk?: BasicAsyncHandler;
    onClose?: BasicAsyncHandler;
};

/*
    general info popup
*/
export class InfoPopup extends BasicPopup {
    public override preload() {
        return [...super.preload()];
    }

    // @ts-ignore
    public override async spawning(options: InfoPopupOptions) {
        super.spawning({
            header: options.title,
            width: 600,
            height: 410,
            ...options,
        });

        // spawn
        this.main.addContent({
            sometext: {
                content: new BasicText({
                    text: options.infoText,
                    style: {
                        align: 'center',
                        fill: 'fff4e6',
                        strokeThickness: 4,
                        fontSize: 22,
                        fontWeight: 'bold',
                        lineJoin: 'round',
                        wordWrap: true,
                        wordWrapWidth: this.main.width - 50,
                    },
                }),
                styles: {
                    position: 'center',
                },
            },
        });
    }
}
