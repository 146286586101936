//TODO: refactor + consider TextImageButton
import { uiAlignCenter, uiSizeToFit } from '../../../../lib/pixi/uiTools';
import { onUpAnimation } from '../../util/clickAnimation';
import { BasicText } from '../text/BasicText';
import { SliceButton } from './SliceButton';
import { TextSliceButtonOptions } from './TextSliceButton';

// types
//-----------------------------------------------------------------------------
type TextSliceCheckboxOptions = TextSliceButtonOptions & { selected: boolean };
/*
    slice checkbox with text in middle
*/
export class TextSliceCheckbox extends SliceButton {
    private _selected: boolean;

    // init
    //-------------------------------------------------------------------------

    public get selected(): boolean {
        return this._selected;
    }

    public set selected(value: boolean) {
        this._selected = value;
        this.texture = value ? this._down : this._up;
    }

    constructor(options: TextSliceCheckboxOptions) {
        super({
            ...options,
            slice: {
                ...options.slice,
                defaultSlice: options.selected ? options.slice.sliceDown : options.slice.sliceUp,
            },
        });

        this.selected = options.selected;

        // add text
        const text = this.addChild(new BasicText(options));

        const padX = options.paddingX ?? 0;
        const padY = options.paddingY ?? 0;
        uiSizeToFit(text, this.width - padX * 2, this.height + padY * 2);
        // center it
        uiAlignCenter(this, text);

        // add optional offset to the center pos
        text.x = text.x + (options.offsetX ?? 0);
        text.y = text.y + (options.offsetY ?? 0);
    }

    // private: handlers
    //-------------------------------------------------------------------------
    protected override async _onTap() {
        super._onTap();
        this._selected = true;
    }

    protected override _onDown() {
        super._onDown();
        this._selected = true;
    }

    protected override _onUp() {
        if (this._animate) {
            onUpAnimation(this);
        }
        // play animation but dont change back the texture
    }
}
