import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { TouchInputComponent } from '../../../../lib/pixi/components/TouchInputComponent';
import { onDownAnimation, onTapAnimation, onUpAnimation } from '../../util/clickAnimation';
import { BasicText, BasicTextOptions } from '../text/BasicText';

// types
//-----------------------------------------------------------------------------
export type TextButtonOptions = {
    animate: boolean;
    sound?: string;
};

/*
    basic clickable text
*/
export class TextButton extends BasicText {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onPress?: BasicAsyncHandler;
    // input
    private _animate = false;
    private _sound: string;
    // components
    private _input: TouchInputComponent;

    // properties
    //-------------------------------------------------------------------------
    public set sound(sound: string) {
        this._sound = sound;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: TextButtonOptions & BasicTextOptions) {
        super(options);

        // set pivot props as center to make animation scale from the middle
        if (options.animate) this._animate = true;
        this._input = Object.assign(new TouchInputComponent(this), {
            onTap: this._onTap.bind(this),
            onDown: this._onDown.bind(this),
            onUp: this._onUp.bind(this),
        });

        this._sound = options.sound === null ? null : options.sound || 'button2.ogg';

        // set initial up state
        this._onUp();
    }

    // private: handlers
    //-------------------------------------------------------------------------
    private _onTap() {
        if (this._animate) {
            onTapAnimation(this);
        }

        // 4 dupes to support button spam clicks
        if (this._sound) app.sound.play(this._sound, { dupes: 5, volume: 0.6 });
        this.onPress?.();
    }

    private _onDown() {
        if (this._animate) {
            onDownAnimation(this);
        }

        // todo change text color on press?
    }

    private _onUp() {
        if (this._animate) {
            onUpAnimation(this);
        }
        // todo change text color on press?
    }
}
