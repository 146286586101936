import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';

// types
//-----------------------------------------------------------------------------
export type NpcOptions = {
    id: number;
};

// constants
//-----------------------------------------------------------------------------
const clipId = 'idle';
const scaleMap = [0, 0.6, 0.6, 0.5];
const manifest = {
    spine: (id: number) => `spine.npc0${id}.json`,
};

/*
    concept: Npc
*/
export class Npc extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // init
    //-------------------------------------------------------------------------
    static assets(options?: NpcOptions) {
        return [manifest.spine(options.id)];
    }

    public set speed(value: number) {
        this._spine.speed(value);
    }

    constructor(options?: NpcOptions) {
        super();

        // spawn
        this._spawn(options.id);
    }

    // api
    //-------------------------------------------------------------------------
    public async start(loop = false) {
        await this._spine.start({ id: clipId, loop, mix: 0.2 });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(id: number) {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine(id) }));
        spine.scale.set(scaleMap[id]);
        this.addChild(spine);
    }
}
