import { Dict } from '@pixi/utils';
import { MeshMaterial, Program, Texture, TextureMatrix, WRAP_MODES } from 'pixi.js';

import { objectAccess } from '../../../replicant/util/jsTools';
import { positionOne, PositionType, positionZero, SizeType } from '../../defs/types';
import { Color } from '../Color';
import { HSL } from '../HSL';
import fshader from '../shaders/uber.frag';
import vshader from '../shaders/uber.vert';

// types
//-----------------------------------------------------------------------------
type EffectId = 'displace' | 'hsl' | 'tile';

type TileEffectDef = {};
type DisplaceEffectDef = {
    map: Texture;
    scale: PositionType;
};
type HslEffectDef = {
    hsl: HSL;
};
type EffectDef = DisplaceEffectDef | HslEffectDef | TileEffectDef;

export type UberEffecs = { [key in EffectId]?: EffectDef };

// constants
//-----------------------------------------------------------------------------
const uniformBuilders: Record<
    EffectId,
    { type: number; builder: (uniforms: Dict<any>, texture: Texture, def: EffectDef) => void }
> = {
    tile: { type: 1, builder: _tile },
    displace: { type: 2, builder: _displace },
    hsl: { type: 4, builder: _hsl },
};

/*
    pixi: ubershader material
*/
export class UberMaterial extends MeshMaterial {
    // fields
    //-------------------------------------------------------------------------
    static _program: Program;

    // properties
    //-------------------------------------------------------------------------
    public static get program(): Program {
        return objectAccess('_program', UberMaterial, () => new Program(vshader, fshader));
    }

    // size
    public get size(): SizeType {
        return this.uniforms.size;
    }

    public set size(size: SizeType) {
        this.uniforms.size = new Float32Array([size.width, size.height]);
    }

    // tile
    public get tileX(): number {
        return this.uniforms.tile[0];
    }

    public set tileX(value: number) {
        this.uniforms.tile[0] = value;
    }

    public get tileY(): number {
        return this.uniforms.tile[1];
    }

    public set tileY(value: number) {
        this.uniforms.tile[1] = value;
    }

    // displace
    public get disOffsetX(): number {
        return this.uniforms.disOffset[0];
    }

    public set disOffsetX(value: number) {
        this.uniforms.disOffset[0] = value;
    }

    public get disOffsetY(): number {
        return this.uniforms.disOffset[1];
    }

    public set disOffsetY(value: number) {
        this.uniforms.disOffset[1] = value;
    }

    // HSL
    public get hue(): number {
        return this.uniforms.hsl[0];
    }

    public set hue(value: number) {
        this.uniforms.hsl[0] = value;
    }

    public set color(value: number) {
        this.uniforms.hsl[0] = Color.from(value).toHsl().hue;
    }

    public get saturation(): number {
        return this.uniforms.hsl[1];
    }

    public set saturation(value: number) {
        this.uniforms.hsl[1] = value;
    }

    public get luminance(): number {
        return this.uniforms.hsl[2];
    }

    public set luminance(value: number) {
        this.uniforms.hsl[2] = value;
    }

    public get hsl(): HSL {
        return new HSL(this.hue, this.luminance, this.saturation);
    }

    public set hsl(hsl: HSL) {
        this.uniforms.hsl = new Float32Array([hsl.hue, hsl.saturation, hsl.luminance]);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(texture: Texture, effects: UberEffecs) {
        const uniforms: Dict<any> = {};
        let types = 0;

        // build uniforms for each effect definition
        for (const [id, effect] of Object.entries(effects)) {
            const entry = uniformBuilders[id as EffectId];
            types |= entry.type;
            entry.builder(uniforms, texture, effect);
        }

        // add general uniforms
        uniforms.types = types;
        uniforms.size = new Float32Array([texture.width, texture.height]);

        // create material
        super(texture, {
            program: UberMaterial.program,
            uniforms,
        });

        // set repeat wrap mode to avoid edge artifacts
        this.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT;
    }
}

// private: factory
//-----------------------------------------------------------------------------
function _tile(uniforms: Dict<any>, texture: Texture, def: TileEffectDef) {
    // set uniforms
    uniforms.tile = new Float32Array([0, 0]);
}

function _displace(uniforms: Dict<any>, texture: Texture, def: DisplaceEffectDef) {
    // set repeat wrap mode to avoid edge artifacts
    def.map.baseTexture.wrapMode = WRAP_MODES.REPEAT;

    // set uniforms
    uniforms.disMap = def.map;
    uniforms.disOffset = new Float32Array([0, 0]);
    uniforms.disScale = new Float32Array([def.scale.x, def.scale.y]);
    uniforms.uvBounds = _getUvBounds(texture);
}

function _hsl(uniforms: Dict<any>, texture: Texture, def: HslEffectDef) {
    // set uniforms
    uniforms.hsl = new Float32Array([def.hsl.hue, def.hsl.saturation, def.hsl.luminance]);
}

// private: support
//-----------------------------------------------------------------------------
function _getUvBounds(texture: Texture): Float32Array {
    // get texture matrix
    const tm = new TextureMatrix(texture);
    tm.update();

    // get map extents
    const m = tm.mapCoord;
    const p0 = m.apply(positionZero);
    const p1 = m.apply(positionOne);

    // convert to uv bounds
    return new Float32Array([Math.min(p0.x, p1.x), Math.min(p0.y, p1.y), Math.max(p0.x, p1.x), Math.max(p0.y, p1.y)]);
}
