import { Sound } from '@pixi/sound';
import { Plugin, PluginOptions } from '@play-co/astro';
import { Assets } from 'pixi.js';

import { animate } from '../../lib/animator/Animation';
import { SoundChannel } from '../../lib/sound/SoundChannel';
import { tween } from '../../lib/util/tweens';
import { objectCreateWithProperty } from '../../replicant/util/jsTools';
import type { App } from '../App';

// settings
//-----------------------------------------------------------------------------
const fadeTime = 0.5;
const defaultVolume = 0.5;

// types
//-----------------------------------------------------------------------------
// public
export type MusicServiceOptions = PluginOptions;

/*
    NOTES: very simple music service
    TODO:  fade in/out, pause/resume(?)
*/
export class MusicService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    public static readonly DEFAULT_VOLUME = 1;
    public static readonly LIMITED_VOLUME = 0.1;

    private _musicChannel: SoundChannel;

    // properties
    //-------------------------------------------------------------------------
    public set mute(mute: boolean) {
        if (mute) {
            this._musicChannel.muteAll();
            this._musicChannel.pauseAll();
        } else {
            this._musicChannel.unmuteAll();
            this._musicChannel.resumeAll();
        }
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<MusicServiceOptions>) {
        super(app, options);
    }

    public async init() {
        //TODO: currently broken in pixi. loads all music despite preload being off.
        // load music manifest. this should load instantly since assets should
        // be set not to preload.
        //await Assets.loadBundle(['assets/music']);

        // create music channel
        this._musicChannel = new SoundChannel('bgm');
    }

    public start(): void {}

    // api
    //-------------------------------------------------------------------------
    public async play(name: string) {
        //TODO: workaround to: await Assets.loadBundle(['assets/music']);
        // load asset on demand
        await Assets.load(name);

        // require first tap cuz browser security reasons :\
        // await firstTapPromise;

        // if already playing, keep playing it
        if (this._musicChannel.getActiveSoundAliases().includes(name)) return;

        // fade out any existing music
        this.fade();

        // create entry
        if (!this._musicChannel.exists(name)) {
            const sound = Assets.get<Sound>(name);
            this._musicChannel.add(name, sound);
        }

        //BUG?: this is needed to reset volume that was previously lowered
        // since following play/volume param appears to be ignored
        this._musicChannel.volume(name, defaultVolume);

        // play after we made sure we have the music added
        await this._musicChannel.play(name, {
            loop: true,
            dupes: 0,
            volume: defaultVolume,
        });
    }

    public async fade(duration = fadeTime) {
        const playing = this._musicChannel.getActiveSoundAliases();

        // animate fade out
        await Promise.all(
            playing.map(async (name) => {
                const wrapper = objectCreateWithProperty(
                    'value',
                    (value: number) => this._musicChannel.volume(name, value),
                    this._musicChannel.volume(name),
                );
                await animate().add(wrapper, { value: 0 }, duration, tween.linear).promise();
                this._musicChannel.stop(name);
            }),
        );
    }

    public async stop() {
        this._musicChannel.stopAll();
    }
}
