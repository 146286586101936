import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';
import { PlantId } from '../../replicant/defs/items';
import { assertNever } from '../../replicant/util';

// types
//-----------------------------------------------------------------------------
export type PlantOptions = {
    id: PlantId;
};

// constants
//-----------------------------------------------------------------------------
const clipId = 'idle';
function tweaks(id: PlantId) {
    switch (id) {
        case '01':
        case '02':
        case '03':
        case '06':
        case '_08':
            return { scale: 0.7 };
        case '_01':
            return { scale: 0.525 };
        case '04':
            return {};
        case '05':
            return { scale: 0.6 };
        case '_07':
            return { x: 30, y: 100, scale: 0.55 };
        default:
            return assertNever(id);
    }
}
const manifest = {
    spine: (id: PlantId) => `spine.plant${id}.json`,
};

/*
    concept: Plant
*/
export class Plant extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // init
    //-------------------------------------------------------------------------
    static assets(options?: PlantOptions) {
        return [manifest.spine(options.id)];
    }

    public set speed(value: number) {
        this._spine.speed(value);
    }

    constructor(options?: PlantOptions) {
        super();

        // spawn
        this._spawn(options.id);
    }

    // api
    //-------------------------------------------------------------------------
    public async start(loop = false) {
        await this._spine.start({ id: clipId, loop, mix: 0.2 });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(id: PlantId) {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine(id) }));

        const { x = 0, y = 0, scale = 1 } = tweaks(id);
        spine.scale.set(scale);
        this.pivot.set(x + this.width / 2, y + this.height / 2);
        this.addChild(spine);
    }
}
