import gameConfig from '../defs/gameConfig';
import { LanguageId } from '../defs/settings';

export type ReplicantCreativeType = 'firstSession0' | 'firstSession1' | 'firstSession2';

export type CreativeText = {
    id: string;
    cta: string;
    text: string;
    title: string;
};

type ReplicantMessagesConfig = {
    [lang: string]: Record<
        ReplicantCreativeType,
        {
            [bucket: string]: { title?: string; text: string; cta: string };
        }
    >;
};

const config: ReplicantMessagesConfig = {
    en: {
        firstSession0: {
            control: {
                text: 'My flowers are being eaten by bugs! Come quickly and help me chase them away!',
                cta: 'Play!',
            },
        },
        firstSession1: {
            control: {
                text: `My flowers are wilting! Let's give them some water to revive them!`,
                cta: 'Play!',
            },
        },
        firstSession2: {
            control: {
                text: `The garden is on fire! Let's extinguish it quickly!`,
                cta: 'Play!',
            },
        },
    },
    ja: {
        firstSession0: {
            control: {
                text: 'お花が虫に食われてる！早くきて追い払ってあげよう！',
                cta: 'プレイ！',
            },
        },
        firstSession1: {
            control: {
                text: 'お花たちが枯れちゃうよ！お水をあげて元気にしてあげよう！',
                cta: 'プレイ！',
            },
        },
        firstSession2: {
            control: {
                text: 'お庭が火事になっちゃった！早く消火しよう！',
                cta: 'プレイ！',
            },
        },
    },
};

// Random among text
export function getCreativeText(
    locale: LanguageId,
    type: ReplicantCreativeType,
    random: () => number,
    substitutions?: { [key: string]: any },
    titleSubstitutions?: { [key: string]: any },
): CreativeText {
    // Fallback locale if we don't have the current locale or its not translated
    const lang = config[locale] ? locale : gameConfig.settings.language;
    const messagesObj = config[lang][type];
    const messagesArr = Object.keys(messagesObj);
    const messageIdx = Math.floor(random() * messagesArr.length);
    const messageId = messagesArr[messageIdx];
    const message = messagesObj[messageId];

    let text = message.text;
    if (substitutions) {
        for (const token in substitutions) {
            text = text.replace(`{${token}}`, substitutions[token]);
        }
    }

    let title = message.title;
    if (title && titleSubstitutions) {
        for (const token in titleSubstitutions) {
            title = title.replace(`{${token}}`, titleSubstitutions[token]);
        }
    }

    return {
        id: messageId,
        cta: message.cta,
        text,
        title,
    };
}

export function getCreativeTextSpecific(
    locale: LanguageId,
    type: ReplicantCreativeType,
    subType: string,
    substitutions?: { [key: string]: any },
    titleSubstitutions?: { [key: string]: any },
): CreativeText {
    // Fallback locale if we don't have the current locale or its not translated
    const lang = config[locale] ? locale : gameConfig.settings.language;
    const messagesObj = config[lang][type];
    const message = messagesObj[subType];

    let text = message.text;
    if (substitutions) {
        for (const token in substitutions) {
            text = text.replace(`{${token}}`, substitutions[token]);
        }
    }

    let title = message.title;
    if (title && titleSubstitutions) {
        for (const token in titleSubstitutions) {
            title = title.replace(`{${token}}`, titleSubstitutions[token]);
        }
    }

    return {
        id: subType,
        cta: message.cta,
        text,
        title,
    };
}
