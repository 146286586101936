import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import garden, { PlotId } from '../../replicant/defs/garden';
import { sleep } from '../../replicant/util/jsTools';
import { PlantPopup } from '../lib/ui/popups/PlantPopup';
import { HomeScreen } from '../main/garden/HomeScreen';

type GardenFlowOptions = {
    screen: HomeScreen;
};

export class GardenFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();
    private _options: GardenFlowOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: GardenFlowOptions) {
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionGarden();
        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _actionGarden() {
        const exitPromise = new NakedPromise();
        const popupId = 'plantPopup';
        const plantPopup = (await app.nav.open(popupId, {
            gardenState: app.server.state.garden,
            onClose: exitPromise.resolve,
        })) as PlantPopup;

        plantPopup.onItemPress = async (itemId: PlotId) => {
            this._itemPress(itemId);
            // close popup
            exitPromise.resolve();
        };

        await exitPromise;
        app.nav.close(popupId);
        this._actionComplete(true);
    }

    private async _itemPress(plotId: PlotId) {
        const { screen } = this._options;

        if (app.server.state.garden.plots[plotId].level === garden.maxPlantLevel) {
            await screen.showPlayPuzzle();
            // keep popup open, dont resolve promise
            return;
        }

        await app.server.invoke.upgradePlot({ plotId });

        const isPlanted = app.server.state.garden.plots[plotId].level > 0;

        await screen.bouncePlayer(plotId);
        screen.playPlayerAnimation(isPlanted ? 'planting' : 'cleaning').then(() => {
            screen.playPlayerAnimation(Math.random() < 0.5 ? 'happy_loop' : 'happy_loop2', true);
        });

        await screen.updatePlots(plotId, app.server.state.garden);

        await sleep(1.4);
        screen.isInteracting = false;
        screen.centerPlayerX();
    }
}
