import { PlotId } from '../defs/garden';

export const getCleanPlotEvent = (eventProperties: { plot: PlotId }) => ({
    eventType: 'CleanPlot',
    eventProperties,
});

export const getUpdatePlotEvent = (eventProperties: { plot: PlotId; level: number }) => ({
    eventType: 'UpdatePlot',
    eventProperties,
});
