import { Ticker } from 'pixi.js';

import { BasicHandler } from '../../defs/types';

// types
//-----------------------------------------------------------------------------
export type PerformanceMonitorOptions = {
    listener?: BasicHandler;
};

// constants
//-----------------------------------------------------------------------------
const settings = {
    every: 1, // update every this many seconds
};

/*
    performance monitor
*/
export class PerformanceMonitor {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _options: PerformanceMonitorOptions;
    // state
    private _frames: number;
    private _fps: number;
    private _updateTime: number;
    private _updateFrames: number;
    // handlers
    private _stepHandler = this._stepFrame.bind(this);

    // properties
    //-------------------------------------------------------------------------
    public get fps(): number {
        return this._fps;
    }

    public get frames(): number {
        return this._frames;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options?: PerformanceMonitorOptions) {
        this._options = options || {};
    }

    // api
    //-------------------------------------------------------------------------
    public start(): void {
        // reset state
        this._frames = 0;
        this._fps = 0;
        this._updateTime = this._timeNow();
        this._updateFrames = 0;

        // register events
        Ticker.system.add(this._stepHandler);
    }

    public stop(): void {
        // unregister events
        Ticker.system.remove(this._stepHandler);
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _stepFrame() {
        const now = this._timeNow();
        const dtime = now - this._updateTime;

        // update frame counter
        ++this._frames;

        // update metrics every N seconds
        if (dtime > settings.every) {
            const frames = this._frames;

            // solve metrics
            const dframes = frames - this._updateFrames;
            this._fps = dframes / dtime;

            // set new state
            this._updateTime = now;
            this._updateFrames = frames;

            // notify
            this._options.listener?.();
        }
    }

    // private: support
    //-------------------------------------------------------------------------
    private _timeNow(): number {
        return performance.now() / 1000;
    }
}
