import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';

// types
//-----------------------------------------------------------------------------
// clip
export const onionGuyClipIds = [
    'cleaning',
    'excited',
    'happy_loop',
    'happy_loop2',
    'idle',
    'idle2',
    'new', // not used??
    'planting',
    'sad',
    'walk',
] as const;
export type OnionGuyClipId = (typeof onionGuyClipIds)[number];

// constants
//-----------------------------------------------------------------------------
const manifest = {
    spine: 'spine.onion_guy.json',
};

/*
    concept: OnionGuy
*/
export class OnionGuy extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // init
    //-------------------------------------------------------------------------
    static assets() {
        return Object.values(manifest);
    }

    constructor() {
        super();

        // spawn
        this._spawn();
    }

    // api
    //-------------------------------------------------------------------------
    public async start(id: OnionGuyClipId, loop = false) {
        // stop previous
        this._spine.stop();
        // no mix for the action clips
        // const mix = id !== 'planting' && id !== 'cleaning' ? 0.2 : 0;
        const mix = id !== 'planting' && id !== 'cleaning' ? 0.1 : 0;
        await this._spine.start({ id, loop, mix });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine }));
        spine.scale.set(0.3);
        this.pivot.set(this.width / 2, this.height / 2);
        // this.pivot.set(this.width / 2, this.height);
        this.addChild(spine);
    }
}
