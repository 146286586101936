import { waitAFrame } from '@play-co/astro';
import type { Platform } from '@play-co/gcinstant';
import { type ClientReplicant, type ReplicantFromConfig } from '@play-co/replicant';

import { INVITE_FEATURE } from '../../replicant/analytics/invite';
import config from '../../replicant/config';
import { hexDecode } from '../../replicant/util/encoder';

type ReplicantClient = ClientReplicant<ReplicantFromConfig<typeof config>>;

class CoreTech {
    public get platform(): Platform {
        return window.CoreTech.platform;
    }

    public get replicant(): ReplicantClient {
        return window.CoreTech.replicant;
    }

    public get loadPromise(): Promise<void> {
        return window.CoreTech.loadPromise;
    }

    /** Hides the loading screen */
    public async start(): Promise<void> {
        await waitAFrame();

        await this.platform.startGameAsync();
    }

    public async sendEntryFinal(): Promise<void> {
        this.overrideEncodedPlayerIdAndZeroEntrySourcePlayerId();

        await this.platform.waitForEntryAnalytics();

        const entryData = this.platform.entryData as { [key: string]: unknown };
        const entryFinalEventProps = {
            $creativeAssetID: entryData.$creativeAssetID,
            $creativeTextID: entryData.$creativeTextID,
            $creativeCTA: entryData.$creativeCTA,
            isAppSwitch: entryData.isAppSwitch,
        };

        // TODO need to fill in game specific props here
        void this.platform.sendEntryFinalAnalytics(entryFinalEventProps, {}, {});
    }

    public async refreshUserState(refreshSignature: boolean): Promise<void> {
        await this.replicant.refresh(refreshSignature ? this.platform.playerSignature : null);
    }

    private overrideEncodedPlayerIdAndZeroEntrySourcePlayerId() {
        const entryData = this.platform.entryData as { [key: string]: unknown };

        if (entryData.feature === INVITE_FEATURE) {
            if ((entryData.playerID as string).startsWith('U')) {
                // Player ID starts with 'U' is an original LINE ID, no need to decode
                return;
            }

            const decodedPlayerId = hexDecode(entryData.playerID as string);
            this.platform.entryData.playerID = decodedPlayerId;

            if (
                typeof entryData.zeroEntrySourcePlayerID === 'string' &&
                !entryData.zeroEntrySourcePlayerID.startsWith('U')
            ) {
                const decodedZeroEntrySourcePlayerId = hexDecode(this.platform.entryData.zeroEntrySourcePlayerID);
                this.platform.entryData.zeroEntrySourcePlayerID = decodedZeroEntrySourcePlayerId;
            }
        }
    }
}

export const coreTech = new CoreTech();
