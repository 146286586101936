import { Container, DisplayObject, Graphics, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../index.entry';
import { Animation } from '../../../lib/animator/Animation';
import { SizeType } from '../../../lib/defs/types';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import {
    uiAlignBottom,
    uiAlignCenter,
    uiAlignCenterX,
    uiCreateMask,
    uiCreateQuad,
    uiSizeToFit,
} from '../../../lib/pixi/uiTools';
import { Line } from '../../../lib/ui/widgets/Line';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { getPlantItemProps } from '../../../replicant/components/garden';
import garden, { Garden, PathId, Plot, PlotId } from '../../../replicant/defs/garden';
import { PlantId } from '../../../replicant/defs/items';
import { arrayShuffle, sleep } from '../../../replicant/util/jsTools';
import { isAbTestInBucket } from '../../../replicant/util/replicantTools';
import { PlotComponent } from '../../components/PlotComponent';
import { Cleanup } from '../../concept/Cleanup';
import { Npc } from '../../concept/Npc';
import { OnionGuy, OnionGuyClipId } from '../../concept/OnionGuy';
import { Plant } from '../../concept/Plant';
import { pixiConfig } from '../../defs/config';
import { GardenFlow } from '../../flows/GardenFlow';
import { PlantFlow } from '../../flows/PlantFlow';
import { trackPlayerPet } from '../../lib/analytics/pets';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { Pointer } from '../../lib/ui/Pointer';
import { BasicText } from '../../lib/ui/text/BasicText';

type ActionType = 'plant' | 'puzzle';

const SPEECH_SPEED = 0.06;
const DEFAULT_Y_OFF = 520;
const DEFAULT_Y_BOTTOM = 480;
const DEFAULT_Y_MIDDLE = 690;
const DEFAULT_Y_TOP = 900;

const POINTER_SCALE = 0.45;

const MAIN_UI_Z = 10000;

const DEFAULT_DIALOGS = [
    '[tapDialog0]',
    '[tapDialog1]',
    '[tapDialog2]',
    '[tapDialog3]',
    '[tapDialog4]',
    '[tapDialog5]',
    '[tapDialog6]',
    '[tapDialog7]',
    '[tapDialog8]',
    '[tapDialog9]',
    '[tapDialog10]',
    '[tapDialog11]',
    '[tapDialog12]',
    '[tapDialog13]',
    '[tapDialog14]',
    '[tapDialog15]',
    '[tapDialog16]',
    '[tapDialog17]',
    '[tapDialog18]',
    '[tapDialog19]',
    '[tapDialog20]',
    '[tapDialog21]',
    '[tapDialog22]',
    '[tapDialog23]',
    '[tapDialog24]',
    '[tapDialog25]',
    '[tapDialog26]',
    '[tapDialog27]',
    '[tapDialog28]',
    '[tapDialog29]',
];

// types
//-----------------------------------------------------------------------------
export type HomeScreenOptions = {
    gardenState: Garden;
    hideMenu?: boolean;
    skipPointer?: boolean;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    // garden
    bigBubble: 'bubble.large.png',
    smallBubble: 'bubble.small.png',
    bubbleTail: 'bubble.tail.png',
    plant: 'button.plant.png',
    puzzle: 'button.puzzle.png',
    default: 'bg.garden.png',
};

const DEFAULT_ACTIONS = ['plant', 'puzzle'] as ActionType[];

export class HomeScreen extends LayoutScreen2 {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _bg: NineSlicePlane;
    private _bgMask: Graphics;
    private _menuContainer: Container;
    private _playerView: Container;
    private _player: OnionGuy;
    private _isInteracting = false;
    private _playerAnimation: Animation;
    private _pointer: Pointer;
    private _pointerAnimation: Animation;
    private _name: BasicText;
    private _tapDialogs: string[];
    private _notificationText: BasicText;
    private _notificationAnimation: Animation;
    private _skipSpeechCount = 0; // larger than 0 means skip speech, larger than 1 speech and bubble delay
    private _underlayInput: TouchInputComponent;
    private _plots: Record<PlotId, PlotComponent>;
    private _pointerIdleTime = 0;
    private _gardenPopup = false;
    private _skipPointer = false;

    //----------  debug
    private _debug = false;
    private _playerZ: Graphics;
    private _moveTarget: PathId; // current move target, near~ plot + extra in the middle for more randomness
    private _plantPath = true; // alt pathing toggled from cheat.
    //----------

    public set isInteracting(value: boolean) {
        this._isInteracting = value;
    }

    public get isInteracting() {
        return this._isInteracting;
    }

    public set plantPath(value: boolean) {
        this._plantPath = value;
    }

    public get nameView() {
        return this._name;
    }

    public get flyAnimation() {
        return this._playerAnimation;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload(options: HomeScreenOptions) {
        return app.resource.loadAssets([
            ...Object.values(manifest),
            ...PlotComponent.assets(),
            ...OnionGuy.assets(),
            ...Cleanup.assets(),
            ...Pointer.assets(),
            ...Npc.assets({ id: 1 }),
            ...Npc.assets({ id: 2 }),
            ...Npc.assets({ id: 3 }),
            ...Plant.assets({ id: '04' }),
            ...Object.values(getPlantItemProps(app.server.state)).reduce((urls, { id }) => {
                urls.push(...Plant.assets({ id }));
                return urls;
            }, []),
        ]);
    }

    public async init() {}

    public step(dt: number) {
        if (!this._skipPointer) {
            if (!this.isInteracting && !this._gardenPopup) {
                this._pointerIdleTime += dt;
            }
            if (this._pointerIdleTime > 5 && !this._pointer) {
                this._spawnPointer();
                this._pointerIdleTime = 0;
            }
        }

        this._playerView.zIndex = this._playerView.y + 140;
        const pZ = this._playerView.zIndex;
        if (this._debug) {
            this._playerZ?.destroy();
            this._playerZ = new Line({
                from: { x: 0, y: pZ },
                to: { x: this._bg.width, y: pZ },
                size: 2,
                color: 0xfff,
            });
            this._bg.addChild(this._playerZ);
        }
    }

    public async spawning(options: HomeScreenOptions) {
        this._plots = {} as Record<PlotId, PlotComponent>;

        this._skipPointer = !!options.skipPointer;

        // play music
        app.music.play('bgm.ogg');

        this._isInteracting = false;

        // reset dialogs
        this._tapDialogs = arrayShuffle([...DEFAULT_DIALOGS]);

        // spawn scene
        this._spawn(options);

        if (options.hideMenu) {
            // if we hide main menu then disable the plot interactions as well
            for (const plotId of Object.keys(this._plots) as PlotId[]) {
                // disable button interactions if init state is added
                this._plots[plotId].view.interactive = false;
            }
        } else {
            const showPlantMenu = !isAbTestInBucket(app.server.state, '0001_FirstSessionImprovements', 'ver.2');
            const menuItems = DEFAULT_ACTIONS.filter((menu) => menu !== 'plant' || showPlantMenu);
            this.spawnMenu(menuItems, true);

            this.startPlayer();
        }

        this._bgMask = uiCreateMask(this._bg.width, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);

        // preload
        sleep(0.5).then(() => app.nav.preload('plantPopup'));
    }

    public despawned() {
        this.empty();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        this._bg.height = size.height;
        this._bgMask.height = size.height;

        if (this._menuContainer) {
            uiAlignBottom(this._bg, this._menuContainer, -40);
        }
    }

    public forcedResize(): void {
        const size = {
            width: app.stage.canvas.width,
            height: app.stage.canvas.height,
        };
        this.resized(size);
    }

    public async despawnMenu() {
        let containerPromise;
        let framePromise;
        if (this._menuContainer) {
            containerPromise = this._menuContainer.animate().add(this._menuContainer, { alpha: 0 }, 0.3, tween.pow2Out);
        }
        await Promise.all([containerPromise, framePromise]);
        this._menuContainer?.removeSelf();

        this._menuContainer = null;
    }

    // toggle from outside
    public spawnMenu(buttons: ActionType[], animate = false): ImageButton[] {
        const buttonMap: Record<ActionType, Pick<ImageButton, 'x' | 'onPress'>> = {
            plant: {
                x: 0,
                onPress: this.onGarden.bind(this),
            },
            puzzle: {
                x: 515,
                onPress: this.onPuzzle.bind(this),
            },
        };

        if (buttons.length === 0) return [];

        const buttonViews = [];
        const container = new Container();
        for (const id of buttons) {
            const button = new ImageButton({
                image: manifest[id],
            });

            container.addChild(button);
            Object.assign(button, buttonMap[id]);

            buttonViews.push(button);
        }

        if (animate) {
            container.alpha = 0;
            container.animate().add(container, { alpha: 1 }, 0.3, tween.pow2In);
        }

        this._bg.addChild(container);
        uiAlignBottom(this._bg, container, -40);
        if (buttons.length > 1) {
            uiAlignCenterX(this._bg, container);
        } else {
            container.x = 60;
        }

        this._menuContainer = container;

        this._menuContainer.zIndex = MAIN_UI_Z;

        // return in case spawned from flow
        return buttonViews;
    }

    // bounces the player to a plot
    public async bouncePlayer(plotId: PlotId, offsetX = 0): Promise<void> {
        const { x, y } = this._plots[plotId].view.position;
        this.stopMovement();
        this._playerView.x < x ? (this._playerView.scale.x = 1) : (this._playerView.scale.x = -1);
        await this._playerView
            .animate()
            .add(this._playerView.position, { x: x + offsetX, y: y - 150 }, 0.5, tween.backOut(1.7))
            .promise();
    }

    public async centerPlayerX() {
        const targetX = this._bg.width * 0.5;
        const distance = Math.sqrt(Math.pow(this._playerView.x - targetX, 2));
        const walkTime = distance / 160;
        const prevX = this._playerView.x;

        this.playPlayerAnimation('walk', true);

        this._playerAnimation = this._playerView
            .animate()
            .add(this._playerView.position, { x: targetX }, walkTime, tween.linear);
        prevX > targetX ? (this._playerView.scale.x = -1) : (this._playerView.scale.x = 1);

        await this._playerAnimation.promise();
        this.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);
        this.startPlayer();
    }

    public async updatePlots(updatedId: PlotId, gardenState: Garden, disablePlots = false) {
        const plot = gardenState.plots[updatedId];
        // clean, extra animation + separate fade of dirt
        if (gardenState.plots[updatedId].level === 0) {
            await sleep(0.1);
            const cleanup = new Cleanup();
            const plotPos = this._plots[updatedId].view.position;
            cleanup.position.set(plotPos.x - 40, plotPos.y);
            this._bg.addChild(cleanup);
            const fadePromise = this._plots[updatedId].view
                .animate()
                .add(this._plots[updatedId].view, { alpha: 0 }, 0.5, tween.backOut())
                .promise();

            app.sound.play('buff1.ogg', { volume: 0.5 });
            await Promise.all([cleanup.start(), fadePromise]);
            cleanup.removeFromParent();
            this._plots[updatedId].view.destroy();
        } else {
            await this._plots[updatedId].view
                .animate()
                .add(this._plots[updatedId].view, { alpha: 0 }, 0.5, tween.backOut())
                .promise();

            app.sound.play('buff1.ogg', { volume: 0.5 });
        }

        this._plots[updatedId].view.destroy();

        // replace with new asset
        const plotComponent = this._createPlot(updatedId, plot, { hideMenu: !!disablePlots });
        this._plots[updatedId] = plotComponent;
        const plotButton = plotComponent.view;
        this._addPlot(updatedId, plotButton);
        plotButton.interactive = !disablePlots;
        plotButton.scale.set(0.1);
        await plotButton.animate().add(plotButton.scale, { x: 1, y: 1 }, 0.35, tween.backOut()).promise();
    }

    public playPlayerAnimation(animation: OnionGuyClipId, loop = false): Promise<void> {
        this._bg.sortableChildren = true;
        return this._player.start(animation, loop);
    }

    public async spawnSpeechSequence(dialogs: string[], promise?: NakedPromise) {
        this._isInteracting = true;
        const speechMap = {
            dialogOffset: 6,
            bubbleY: -200,
            bubbleOffsetX: -370,
            bubbles: 2,
        };

        const views: Container[] = [];

        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);
        this._underlayInput.enabled = true;
        let finishedSpeech = false;
        this._underlayInput.onTap = async () => this._speechTap(views, finishedSpeech, promise);

        // bubbles
        const defaultX = this._playerView.x + speechMap.bubbleOffsetX;
        const x = defaultX;
        let y = speechMap.bubbleY;
        const bubbleAmount = speechMap.bubbles;
        for (let i = 0; i < dialogs.length; i++) {
            const bubble = new NineSlicePlane(Texture.from(manifest.smallBubble), 200, 70, 200, 70);
            bubble.width = 490;
            bubble.height = 180;
            i === 1 ? (bubble.x = x + 50) : (bubble.x = x);
            bubble.y = y;
            bubble.zIndex = 5000;
            this._playerView.addChild(bubble);
            y += 170;

            if (i === bubbleAmount - 1) {
                const tail = Sprite.from(manifest.bubbleTail);
                tail.scale.x = -1;
                bubble.addChild(tail);
                uiAlignCenter(bubble, tail, -15, 115);
            }

            const maxLabelWidth = bubble.width - 70;
            const label = new BasicText({
                style: {
                    fill: '#000',
                    fontSize: 28,
                    lineJoin: 'round',
                    wordWrap: true,
                    wordWrapWidth: maxLabelWidth,
                    align: 'center',
                },
            });

            bubble.addChild(label);
            const fullText = textLocaleFormat(dialogs[i]);
            views.push(bubble);

            bubble.alpha = 0;
            bubble.animate().add(bubble, { alpha: 1 }, this._skipSpeechCount > 0 ? 0 : 0.3, tween.pow2In);
            await sleep(this._skipSpeechCount > 0 ? 0 : 0.5);

            for (let i = 0; i < fullText.length; i++) {
                label.text = fullText.substring(0, i + 1);
                uiSizeToFit(label, maxLabelWidth, bubble.height - 40);
                uiAlignCenter(bubble, label, 2);

                if (i % 2 === 0) this._playSpeechSound();

                await sleep(this._skipSpeechCount > 0 ? 0 : SPEECH_SPEED);
            }
        }

        finishedSpeech = true;
        this._skipSpeechCount = 0;
        return views;
    }

    public async spawnBubbleTap(text: string, promise?: NakedPromise) {
        this._isInteracting = true;

        const views: Container[] = [];
        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);
        this._underlayInput.enabled = true;
        let finishedSpeech = false;
        const onTap = async () => {
            if (!finishedSpeech) {
                this._skipSpeechCount++;
                return;
            }

            for (const view of views) {
                view.animate()
                    .add(view, { alpha: 0 }, 0.3, tween.pow2Out)
                    .then(() => view.removeSelf());

                promise.resolve();
            }
        };

        // bubble spawn and tap
        const bubble = await this.spawnSpeechBubbleTutorial(text, 0.75, onTap);
        // this step requires tap to continue,
        // re-enable it even though it got disabled in the end of the shared bubble animation above
        this._underlayInput.enabled = true;
        finishedSpeech = true;
        views.push(bubble);
        this._spawnTapContainer(views);
    }

    public async setSetName(name: string, init = false): Promise<void> {
        if (!init) {
            await this._name.animate().add(this._name, { alpha: 0 }, 0.1, tween.linear);
        }

        this._name.text = textLocaleFormat(`[gardenName|${name}]`);
        uiAlignCenterX(this._bg, this._name, -180);
        this._name.y = 30;
    }

    public getPlot(plotId: PlotId) {
        return this._plots[plotId];
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: HomeScreenOptions) {
        // spawn scene
        this._bg = new NineSlicePlane(Texture.from(manifest.default), 0, 0, 0, 1500);
        this._bg.sortableChildren = true;
        this._bg.width = 800;
        this._bg.height =
            app.stage.canvas.height < pixiConfig.size.height ? pixiConfig.size.height : app.stage.canvas.height;

        this.base.addContent({
            bg: {
                content: this._bg,
                styles: {
                    position: 'bottomCenter',
                },
            },
        });

        this._spawnPlots(options);

        const header = this._createHeader();
        this._bg.addChild(header);
        this._spawnNpcs();
        this._spawnStaticPlants();
        // extra container, animated
        this._spawnPlayer(!!options?.hideMenu);
    }

    private _spawnPlayer(hideMenu: boolean) {
        this._player = new OnionGuy();
        this.root.sortableChildren = true;
        const button = new ImageButton({
            // uncomment for debug
            // image: manifest.puzzle,
            sound: 'tap-pet.ogg',
        });
        // uncomment for debug
        // button.alpha = 0.4;

        button.width = 240;
        button.height = 300;
        this._player.zIndex = 4;
        button.zIndex = 10;
        const container = new Container();
        // container.zIndex = 8;
        container.sortableChildren = true;
        container.addChild(button, this._player);

        this._bg.addChild(container);
        container.pivot.set(container.width * 0.5, container.height * 0.5);
        container.position.set(this._bg.width * 0.5, this._bg.height - DEFAULT_Y_OFF);

        uiAlignCenter(container, button);
        uiAlignCenter(container, this._player, button.width * 0.5 - 20, button.height * 0.5 + 80);

        this.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);

        if (!hideMenu) {
            button.onPress = this.onPlayerTap.bind(this);
        }

        this._playerView = container;
        this._playerView.zIndex = this._playerView.y;
    }

    private _createHeader() {
        this._name = new BasicText({
            text: app.game.player.name ? `[gardenName|${app.game.player.name}]` : '',
            style: {
                fill: '#FFF',
                fontSize: 38,
                stroke: 0x000,
                strokeThickness: 4,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'left',
            },
        });
        uiAlignCenterX(this._bg, this._name, -180);
        this._name.y = 30;
        this._name.zIndex = MAIN_UI_Z;
        return this._name;
    }

    public stopMovement() {
        this._playerAnimation?.cancel();
        this._playerAnimation = null;
        this.isInteracting = true;
    }

    public async startPlayer() {
        await sleep(0.6);
        while (!this._isInteracting) {
            const prevX = this._playerView.x;
            let x;
            if (this._plantPath) {
                const target = this._getMoveTarget();
                const targetPos = { ...garden.pathPositions }[target];
                const targetY = this._bg.height - targetPos.y;
                x = targetPos.x;
                const distance = Math.sqrt(Math.pow(prevX - x, 2) + Math.pow(this._playerView.y - targetY, 2));
                const walkTime = distance / 160;
                this._playerAnimation = this._playerView
                    .animate()
                    .add(this._playerView.position, { x, y: targetY }, walkTime, tween.linear);
            } else {
                const padding = 180;
                const minX = padding * 0.5;
                const maxX = this._bg.width - padding;
                x = Math.round(Math.random() * maxX + minX);
                let k = 0;
                while (Math.abs(prevX - x) < 125) {
                    x = Math.round(Math.random() * maxX + minX);
                    if (k === 3) {
                        // end loop after 4 iterations and just set a max/min value
                        x = prevX < this._bg.width * 0.5 ? maxX : minX;
                    }
                    k++;
                }

                const y = this._bg.height - (pixiConfig.size.height - 250) + Math.random() * (this._bg.height - 850);
                const distance = Math.sqrt(Math.pow(prevX - x, 2) + Math.pow(this._playerView.y - y, 2));

                const walkTime = distance / 160;
                this._playerAnimation = this._playerView
                    .animate()
                    .add(this._playerView.position, { x, y }, walkTime, tween.linear);
            }

            this.playPlayerAnimation('walk', true);
            prevX > x ? (this._playerView.scale.x = -1) : (this._playerView.scale.x = 1);

            if (this._pointer) {
                this._pointer.scale.x = this._playerView.scale.x * POINTER_SCALE;
            }

            await this._playerAnimation;
            // started talking mid animation, skip
            if (this._isInteracting) return;

            this.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);
            await sleep(Number(Math.random() * 3.25 + 0.75)); //random idle sleep before moving again
        }
    }

    public async showPlayPuzzle() {
        await this._showNotification('[playPuzzle]');
    }

    private async onPlayerTap() {
        this._pointerIdleTime = 0;
        if (this._underlayInput) this._underlayInput.enabled = false;
        if (this._isInteracting) {
            this._skipSpeechCount++;
            return;
        }

        this._isInteracting = true;
        trackPlayerPet();

        this._skipSpeechCount = 0;

        const bubble = await this._spawnSpeechBubble();

        if (this._skipSpeechCount < 2) {
            // if tapped 3+ times skip sleep after bubble.
            for (let i = 0; i < 10; i++) {
                if (this._skipSpeechCount > 1) break; // can be mutated from another tap callback, break if needed.
                await sleep(0.2); // sleep 2s total
            }
        }

        this._isInteracting = false;

        await bubble.animate().add(bubble, { alpha: 0 }, 0.3, tween.pow2Out);
        bubble.removeSelf();
        this._skipSpeechCount = 0;
        this._underlayInput.enabled = false;
        this.startPlayer();
    }

    // similar as _spawnSpeechBubble but with no idle animation or direction change
    public async spawnSpeechBubbleTutorial(overrideText: string, moveDelay = 0.75, onTapOverride?: () => Promise<any>) {
        this._playerAnimation?.cancel();
        this._playerAnimation = null;
        // hard coded, always look right at middle pos for tutorial
        this._playerView.scale.x = 1;
        await this._playerView
            .animate()
            .add(
                this._playerView.position,
                { x: this._bg.width * 0.5 - 3, y: this._bg.height - DEFAULT_Y_OFF },
                moveDelay,
                tween.linear,
            );

        const bubble = new NineSlicePlane(Texture.from(manifest.bigBubble), 200, 0, 200, 0);
        bubble.width = 520;

        const tail = Sprite.from(manifest.bubbleTail);
        tail.scale.x = -1;

        bubble.addChild(tail);
        uiAlignCenter(bubble, tail, tail.width + 15, 120);

        const maxLabelWidth = bubble.width - 60;
        const label = new BasicText({
            style: {
                fill: '#000',
                fontSize: 27,
                lineJoin: 'round',
                wordWrap: true,
                wordWrapWidth: maxLabelWidth,
                align: 'center',
            },
        });

        if (this._tapDialogs.length === 0) {
            this._tapDialogs = arrayShuffle([...DEFAULT_DIALOGS]);
        }

        const speechDialog = this._tapDialogs.shift();
        bubble.addChild(label);
        const fullText = overrideText ? textLocaleFormat(overrideText) : textLocaleFormat(speechDialog);
        this._bg.addChild(bubble);
        uiAlignCenterX(this._bg, bubble, 110);
        // global pos to allow for pet direction swap using x scaling
        bubble.y = this._bg.height - this._playerView.height * 2.5;
        bubble.zIndex = 5000;

        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);

        if (onTapOverride) {
            this._underlayInput.onTap = () => onTapOverride();
        } else {
            this._underlayInput.onTap = async () => this._skipSpeechCount++;
        }

        this._underlayInput.enabled = true;
        bubble.alpha = 0;
        bubble.animate().add(bubble, { alpha: 1 }, this._skipSpeechCount > 0 ? 0 : 0.3, tween.pow2In);
        await sleep(this._skipSpeechCount > 0 ? 0 : 0.5);

        for (let i = 0; i < fullText.length; i++) {
            label.text = fullText.substring(0, i + 1);
            uiSizeToFit(label, maxLabelWidth, bubble.height - 40);
            uiAlignCenter(bubble, label, 2);
            if (i % 2 === 0) this._playSpeechSound();

            await sleep(this._skipSpeechCount > 0 ? 0 : SPEECH_SPEED);
        }

        this._skipSpeechCount = 0;
        this._underlayInput.enabled = false;
        return bubble;
    }

    private async _spawnSpeechBubble() {
        this._playerAnimation?.cancel();
        this._playerAnimation = null;
        // -1 right, 1 left
        this._playerView.scale.x = this._playerView.x < this._bg.width * 0.5 ? 1 : -1;

        this._despawnPointer();

        const isLookingLeft = this._playerView.scale.x === 1;
        this.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);

        const bounceMap = {
            top: {
                playerY: this._bg.height - DEFAULT_Y_TOP,
                bubbleY: 800 + (DEFAULT_Y_TOP - DEFAULT_Y_BOTTOM),
            },
            middle: {
                playerY: this._bg.height - DEFAULT_Y_MIDDLE,
                bubbleY: 800 + (DEFAULT_Y_MIDDLE - DEFAULT_Y_BOTTOM),
            },
            bottom: {
                playerY: this._bg.height - DEFAULT_Y_BOTTOM,
                bubbleY: 800,
            },
        };

        const middleX = this._bg.width * 0.5;

        // ------ local helpers
        const calculateDistance = (basePos: 'top' | 'middle' | 'bottom') =>
            Math.sqrt(
                Math.pow(this._playerView.x - middleX, 2) +
                    Math.pow(this._playerView.y - bounceMap[basePos].playerY, 2),
            );
        const getShortestPathKey = () => {
            let shortest;
            let shortestKey = null;
            for (const key of Object.keys(bounceMap) as ('top' | 'middle' | 'bottom')[]) {
                const newDistance = calculateDistance(key);
                if (!shortestKey || newDistance < shortest) {
                    shortest = newDistance;
                    shortestKey = key;
                }
            }
            return shortestKey;
        };
        // ----------------------

        const key = getShortestPathKey();
        if (this._playerView.x !== middleX || this._playerView.y !== bounceMap[key].playerY) {
            await this._playerView
                .animate()
                .add(this._playerView.position, { x: middleX, y: bounceMap[key].playerY }, 0.5, tween.backOut(1.7));
        }
        const bubble = new NineSlicePlane(Texture.from(manifest.bigBubble), 200, 0, 200, 0);
        bubble.width = 520;

        const tail = Sprite.from(manifest.bubbleTail);
        tail.scale.x = isLookingLeft ? -1 : 1;

        bubble.addChild(tail);
        // uiAlignCenter(bubble, tail, isLookingLeft ? -15 : tail.width + 15, 120);
        uiAlignCenter(bubble, tail, isLookingLeft ? tail.width + 15 : -15, 120);

        const maxLabelWidth = bubble.width - 60;
        const label = new BasicText({
            style: {
                fill: '#000',
                fontSize: 27,
                lineJoin: 'round',
                wordWrap: true,
                wordWrapWidth: maxLabelWidth,
                align: 'center',
            },
        });

        if (this._tapDialogs.length === 0) {
            this._tapDialogs = arrayShuffle([...DEFAULT_DIALOGS]);
        }

        const speechDialog = this._tapDialogs.shift();
        bubble.addChild(label);
        const fullText = textLocaleFormat(speechDialog);
        this._bg.addChild(bubble);

        uiAlignCenterX(this._bg, bubble, isLookingLeft ? 110 : -110);
        // global pos to allow for pet direction swap using x scaling
        bubble.y = this._bg.height - bounceMap[key].bubbleY;
        bubble.zIndex = 5000;

        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);

        this._underlayInput.onTap = async () => this._skipSpeechCount++;

        this._underlayInput.enabled = true;
        bubble.alpha = 0;
        bubble.animate().add(bubble, { alpha: 1 }, this._skipSpeechCount > 0 ? 0 : 0.3, tween.pow2In);
        await sleep(this._skipSpeechCount > 0 ? 0 : 0.5);

        for (let i = 0; i < fullText.length; i++) {
            label.text = fullText.substring(0, i + 1);
            uiSizeToFit(label, maxLabelWidth, bubble.height - 40);
            uiAlignCenter(bubble, label, 2);
            if (i % 2 === 0) this._playSpeechSound();

            await sleep(this._skipSpeechCount > 0 ? 0 : SPEECH_SPEED);
        }

        // this._skipSpeechCount = 0;
        // this._underlayInput.enabled = false;
        return bubble;
    }

    private async onGarden() {
        this._pointerIdleTime = 0;
        if (this._isInteracting) {
            this._skipSpeechCount++;
            return;
        }

        this._gardenPopup = true;
        await new GardenFlow({ screen: this }).execute();
        this._gardenPopup = false;
        // reset again in case they kept the popup open for a long time
    }

    private async onPlant(plotId: PlotId) {
        this._pointerIdleTime = 0;
        if (this._isInteracting) {
            this._skipSpeechCount++;
            return;
        }

        // remove pointer if player actually moves to the plant and does an action.
        this._despawnPointer();
        await new PlantFlow({ screen: this, plotId }).execute();
    }

    private async onPuzzle() {
        this._pointerIdleTime = 0;
        await this._showNotification('[checkLater]');
    }

    private _playSpeechSound() {
        if (this._skipSpeechCount > 0) return;
        app.sound.play(`speak${Math.round(Math.random() * 2)}.ogg`, { volume: 0.25, dupes: 2 });
    }

    private async _speechTap(views: Container[], finishedSpeech: boolean, promise: NakedPromise) {
        if (!finishedSpeech) {
            this._skipSpeechCount++;
            // allow current running animation to finish with 0 delay
            return;
        }
        if (promise) {
            for (const view of views) {
                view.animate()
                    .add(view, { alpha: 0 }, 0.3, tween.pow2Out)
                    .then(() => view.removeSelf());
            }
            promise.resolve();
        }
        // this._isInteracting = false;
        this._underlayInput.enabled = false;
    }

    private _spawnTapContainer(views: Container[]) {
        const tapFrame = uiCreateQuad(0x0, 0.3, this._bg.width, 126);

        const tapLabel = new BasicText({
            text: `[tapToContinue]`,
            style: {
                fill: '#FFF',
                fontSize: 32,
                lineJoin: 'round',
                fontWeight: 'bold',
                stroke: 0x0,
                strokeThickness: 3,
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
            },
        });
        tapFrame.zIndex = MAIN_UI_Z;
        tapLabel.zIndex = MAIN_UI_Z;

        views.push(tapFrame, tapLabel);

        this._bg.addChild(tapFrame, tapLabel);
        uiAlignBottom(this._bg, tapFrame);
        uiAlignBottom(this._bg, tapLabel, -35);
        uiAlignCenterX(this._bg, tapLabel);
    }

    private async _showNotification(text: string): Promise<void> {
        this._notificationAnimation?.cancel();
        this._notificationText?.destroy();
        this._notificationText = null;

        this._notificationText = new BasicText({
            text,
            style: {
                fill: '#FFF',
                fontSize: 46,
                lineJoin: 'round',
                fontWeight: 'bold',
                stroke: 0x0,
                strokeThickness: 4,
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
                align: 'center',
            },
        });

        this._notificationText.pivot.set(this._notificationText.width * 0.5, this._notificationText.height * 0.5);
        this._notificationText.zIndex = 9000;
        this._bg.addChild(this._notificationText);
        uiAlignCenter(this._bg, this._notificationText, 0, -60);
        this._notificationText.alpha = 0;
        this._notificationAnimation = this._notificationText
            .animate()
            .add(this._notificationText, { alpha: 1 }, 0.2, tween.pow2In)
            .wait(3.75)
            .add(this._notificationText, { alpha: 0 }, 0.2, tween.pow2Out)
            .then(() => {
                this._notificationText?.destroy();
                this._notificationText = null;
            });
    }

    private _spawnPlots(options: HomeScreenOptions) {
        const { gardenState, hideMenu } = options;
        for (const plotId of Object.keys(gardenState.plots) as PlotId[]) {
            const plot = gardenState.plots[plotId];
            const plotComponent = this._createPlot(plotId, plot, { hideMenu, skipAnimation: true });
            this._plots[plotId] = plotComponent;
            this._addPlot(plotId, plotComponent.view);
        }
    }

    private _addPlot(plotId: PlotId, plotButton: DisplayObject) {
        const { x, y } = garden.plotPositions[plotId];
        plotButton.position.set(x, this._bg.height - y);
        plotButton.zIndex = plotButton.position.y + 80;

        if (this._debug) {
            const line = new Line({
                from: { x: 0, y: plotButton.zIndex },
                to: { x: this._bg.width, y: plotButton.zIndex },
                size: 2,
                color: 0xffa500,
            });
            this._bg.addChild(line);
        }

        this._bg.addChild(plotButton);
    }

    private _createPlot(plotId: PlotId, plot: Plot, optional: { hideMenu?: boolean; skipAnimation?: boolean } = {}) {
        const plotComponent = new PlotComponent(plotId, plot, () => this.onPlant(plotId));

        if (!optional?.hideMenu) {
            if (plot.dirty) {
                void plotComponent.showFloatIcon('clean', true);
            } else if (plot.level === 0) {
                void plotComponent.showFloatIcon('plant', optional.skipAnimation);
            }
        }

        return plotComponent;
    }

    private _spawnNpcs() {
        for (let i = 1; i <= 3; i++) {
            const npc = new Npc({ id: i });
            npc.speed = 0.85;
            this._bg.addChild(npc);
            const pos = garden.npcPositions[`npc0${i}` as 'npc01' | 'npc02' | 'npc03'];
            npc.position.set(pos.x, this._bg.height - pos.y);
            npc.zIndex = npc.position.y + 5;
            npc.start(true);

            if (this._debug) {
                const line = new Line({
                    from: { x: 0, y: npc.zIndex },
                    to: { x: this._bg.width, y: npc.zIndex },
                    size: 2,
                    color: 0xff0000,
                });
                this._bg.addChild(line);
            }
        }
    }

    private _getMoveTarget() {
        const paths = arrayShuffle([...Object.keys(garden.pathPositions)]) as PathId[];

        let pathId = paths.shift();
        if (pathId === this._moveTarget) {
            // same as previous, pick new
            pathId = paths.shift();
        }

        this._moveTarget = pathId;
        return pathId;
    }

    private _spawnStaticPlants() {
        const prePlanted = garden.plantPositions;

        for (const plantId in prePlanted) {
            const id = plantId as PlantId;
            const plants = prePlanted[id];
            for (let i = 0; i < plants.length; i++) {
                const plant = new Plant({ id });
                plant.speed = 0.15;
                this._bg.addChild(plant);
                const pos = plants[i];
                plant.position.set(pos.x, this._bg.height - pos.y);
                plant.zIndex = plant.position.y;
                plant.start(true);

                if (this._debug) {
                    const line = new Line({
                        from: { x: 0, y: plant.zIndex },
                        to: { x: this._bg.width, y: plant.zIndex },
                        size: 2,
                        color: 0x008000,
                    });
                    this._bg.addChild(line);
                }
            }
        }
    }

    private async _spawnPointer() {
        this._pointer = new Pointer({ type: 'hand' });
        this._pointer.zIndex = 5;
        this._player.addChild(this._pointer);
        uiAlignCenterX(this._player, this._pointer, -80);
        const x = this._pointer.x;
        const y = this._pointer.y - 80;

        // this._pointer.scale.x = this._playerView.scale.x * POINTER_SCALE;
        this._pointer.position.set(x, y);
        this._pointer.scale.set(0);
        this._pointerAnimation = this._pointer
            .animate()
            .add(
                this._pointer.scale,
                { x: this._playerView.scale.x * POINTER_SCALE, y: POINTER_SCALE },
                0.35,
                tween.backOut(1.2),
            )
            .add(this._pointer.position, { y: y + 8 }, 0.8, tween.powNInOut(1.1))
            .add(this._pointer.position, { y: y - 8 }, 0.8, tween.powNInOut(1.1))
            .loop();
    }

    private _despawnPointer() {
        if (this?._pointer) {
            this._pointerIdleTime = 0;
            this._pointerAnimation?.cancel();
            this._pointer.scale.x = this._playerView.scale.x * POINTER_SCALE;
            this._pointer
                .animate()
                .add(this._pointer.scale, { x: 0, y: 0 }, 0.2, tween.backIn(1.2))
                .then(() => {
                    this._pointer.destroy();
                    this._pointer = null;
                });
        }
    }
}
