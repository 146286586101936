import { BLEND_MODES, Graphics } from 'pixi.js';

import { BoundsType } from '../../../../lib/defs/types';
import { pixiToLocalBounds } from '../../../../lib/pixi/pixiTools';
import { SpotLight, SpotLightOptions } from './SpotLight';

// types
//-----------------------------------------------------------------------------
export type TargetSpotLightOptions = {
    targets: BoundsType[];
    radius?: number;
    pad?: number;
} & SpotLightOptions;

/*
    spotlight on targets
*/
export class TargetSpotLight extends SpotLight {
    // fields
    //-------------------------------------------------------------------------
    private _options: TargetSpotLightOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: TargetSpotLightOptions) {
        super(options);
        this._options = options;
    }

    // api
    //-------------------------------------------------------------------------
    public override start() {
        // spawn lights around targets. requires we are already spawned
        for (const target of this._options.targets) {
            const bounds = pixiToLocalBounds(this, target);

            // pad the bounds
            bounds.pad(this._options.pad || 0);

            // spawn light
            const light = new Graphics()
                .beginFill()
                .drawRoundedRect(bounds.x, bounds.y, bounds.width, bounds.height, this._options.radius || 0)
                .endFill();

            light.blendMode = BLEND_MODES.ERASE;
            this.addChild(light);
        }
    }
}
