import { action } from '@play-co/replicant';

import { getCleanPlotEvent, getUpdatePlotEvent } from '../analytics/garden';
import { MutableState, State, SyncActionAPI } from '../defs/replicant';
import { getAbTest } from '../util/replicantTools';

// actions
//-----------------------------------------------------------------------------
export const tutorialActions = {
    tutorialSetStep: action((state: MutableState, options: { step: number }) => {
        state.tutorial.garden.step = options.step;
    }),

    tutorialComplete: action((state: MutableState, _, api: SyncActionAPI) => {
        state.tutorial.garden.complete = true;
    }),

    tutorialReset: action((state: MutableState, _) => {
        state.tutorial.garden.complete = false;
        state.tutorial.garden.step = 0;
    }),
    tutorialCleanA: action((state: MutableState, _, api) => {
        state.garden.plots.a.dirty = false;

        const isInV2Test = !!getAbTest(state, '0001_FirstSessionImprovements');
        if (isInV2Test) {
            api.sendAnalyticsEvents([
                getCleanPlotEvent({
                    plot: 'a',
                }),
            ]);
        }
    }),
    tutorialUpgradeA: action((state: MutableState, _, api) => {
        state.garden.plots.a.level = 1;

        const isInV2Test = !!getAbTest(state, '0001_FirstSessionImprovements');
        if (isInV2Test) {
            api.sendAnalyticsEvents([
                getUpdatePlotEvent({
                    plot: 'a',
                    level: state.garden.plots.a.level,
                }),
            ]);
        }
    }),
};

// api
//-----------------------------------------------------------------------------
export function isFullTutorialComplete(state: State): boolean {
    return state.tutorial.garden.complete;
}
