import { SB } from '@play-co/replicant';

// state
//-----------------------------------------------------------------------------
export const playerState = {
    // general
    name: SB.string().default(''),
};

// constants
//-----------------------------------------------------------------------------
