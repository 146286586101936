import { Container, Sprite, TextStyle, Texture } from 'pixi.js';

import app from '../../../index.entry';
import { BasicAsyncHandler } from '../../../lib/defs/types';
import { textLocaleFormat } from '../../../lib/util/textTools';
import gameConfig from '../../../replicant/defs/gameConfig';
import { encodePacket } from '../../../replicant/util/encoder';
import { TextSliceButton } from '../../lib/ui/buttons/TextSliceButton';
import { BasicPopup } from '../../lib/ui/popups/BasicPopup';
import { BasicText } from '../../lib/ui/text/BasicText';

// types
//-----------------------------------------------------------------------------
export type FaqPopupOptions = {
    onClose: BasicAsyncHandler;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    buttonBlue: 'button.blue.png',
};

/*
    faq popup
*/
export class FaqPopup extends BasicPopup {
    // fields
    // scene

    // properties
    //-------------------------------------------------------------------------

    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets(Object.values(manifest))];
    }

    // @ts-ignore
    public override async spawning(options: FaqPopupOptions) {
        super.spawning({
            header: '[faqPopupTitle]',
            width: 600,
            height: 1000,
            underlay: 0.8,
            ...options,
        });

        const faq = this._spawnFaq();
        this.base.addContent({
            faq: {
                content: this._spawnFaq(),
                styles: {
                    position: 'topLeft',
                    marginTop: 120,
                    marginLeft: 60,
                },
            },
            support: {
                content: this._spawnSupport(),
                styles: {
                    position: 'topCenter',
                    marginTop: 120 + faq.height + 30,
                },
            },
        });
    }

    private _spawnFaq() {
        const container = new Container();

        const textStyle: Partial<TextStyle> = {
            align: 'left',
            fill: 'fff4e6',
            fontSize: 24,
            lineJoin: 'round',
            wordWrap: false,
            fontStyle: 'normal',
            leading: 4,
        };
        let offsetY = 0;

        ['faq1', 'faq2'].forEach((faq) => {
            const question = new BasicText({
                text: `[${faq}Question]`,
                style: { ...textStyle, fill: '#132445', fontWeight: 'bold' },
            }).props({
                y: 6 + offsetY,
            });

            const questionBackground = Sprite.from(Texture.WHITE).props({
                y: offsetY,
                width: 480,
                height: question.height + 12,
                tint: '#fff4e6',
                zIndex: -1,
            });

            const answer = new BasicText({
                text: `[${faq}Answer]`,
                style: textStyle,
            }).props({
                y: questionBackground.height + 10 + offsetY,
            });

            offsetY = answer.y + answer.height + 30;

            container.addChild(questionBackground);
            container.addChild(question);
            container.addChild(answer);
            container.sortChildren();
        });

        return container;
    }

    private _spawnSupport() {
        const container = new Container();

        const buttonWidth = 360;
        const buttonHeight = 69;
        const slice = {
            sliceUp: manifest.buttonBlue,
            sliceDown: manifest.buttonBlue,
            width: buttonWidth,
            height: buttonHeight,
            left: 45,
            top: 0,
            right: 45,
            bottom: 0,
        };
        const style = {
            fill: 'fff4e6',
            strokeThickness: 4,
            fontSize: 22,
            fontWeight: 'bold',
            fontStyle: 'italic',
            lineJoin: 'round',
            padding: 2,
        } as Partial<TextStyle>;

        const buttonSettings = new TextSliceButton({
            text: '[faqOpenLineSettings]',
            animate: true,
            offsetY: -3,
            slice,
            style,
        }).props({
            onPress: () => this._openLineSettings(),
        });

        const buttonSupport = new TextSliceButton({
            text: '[faqContactSupport]',
            animate: true,
            offsetY: -3,
            slice,
            style,
        }).props({
            y: buttonSettings.height + 20,
            onPress: () => this._contactSupport(),
        });

        container.addChild(buttonSettings);
        container.addChild(buttonSupport);

        return container;
    }

    private _openLineSettings() {
        window.open('https://line.me/R/nv/connectedApps');
    }

    private _contactSupport() {
        const subject = textLocaleFormat(`[faqContactSubject|${encodePacket(app.game.player.id)}]`);
        window.open(`mailto:${gameConfig.support.email}?subject=${encodeURIComponent(subject)}`);
    }
}
