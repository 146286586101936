import { Container } from 'pixi.js';

import app from '../../../index.entry';
import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { tween } from '../../../lib/util/tweens';
import garden, { Garden } from '../../../replicant/defs/garden';
import { sleep } from '../../../replicant/util/jsTools';
import { isAbTestInBucket } from '../../../replicant/util/replicantTools';
import { NavLayer } from '../../defs/nav';
import {
    trackTutorialFinish,
    trackTutorialStart,
    trackTutorialStepFinish,
    trackTutorialStepStart,
} from '../../lib/analytics/tutorial';
import { HomeScreen } from '../../main/garden/HomeScreen';
import { NamePopup } from '../../main/garden/NamePopup';

// types
//-----------------------------------------------------------------------------
const id = 'garden';

/*
    Garden tutorial flow
*/
export class GardenTutorialFlowV2 implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    private _screen: HomeScreen;
    // state
    private _tutorialOffset: number[];
    // step map
    private _steps = [
        { name: 'clean', handler: this._clean, substeps: 2 },
        { name: 'name', handler: this._name, substeps: 1 },
        { name: 'plant', handler: this._plant, substeps: 2 },
        { name: 'end', handler: this._end, substeps: 1 },
    ];

    private readonly _isInV2Test = isAbTestInBucket(app.server.state, '0001_FirstSessionImprovements', 'ver.2');

    // props
    //-------------------------------------------------------------------------
    // step
    private get step(): number {
        return app.server.state.tutorial.garden.step;
    }

    private set step(step: number) {
        app.server.invoke.tutorialSetStep({ step });
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        this._tutorialOffset = this._steps.reduce(
            (acc, cur) => {
                acc.push(acc[acc.length - 1] + cur.substeps);
                return acc;
            },
            [0],
        );
    }

    // impl
    //-------------------------------------------------------------------------
    async execute(): Promise<void> {
        // run tutorial
        await this._run();

        // complete
        await this._complete();
    }

    // private: control
    //-------------------------------------------------------------------------
    private async _run() {
        // track overall tutorial start
        trackTutorialStart({ stepIndex: 0, stepName: id });

        // for each tutorial step
        for (; this.step < this._steps.length; this.step++) {
            const entry = this._steps[this.step];
            const stepName = `${id}.${entry.name}`;

            // execute tutorial
            await entry.handler.call(this, {
                stepName,
            });
        }
    }

    private async _complete() {
        // complete tutorial
        await app.server.invoke.tutorialComplete();

        trackTutorialFinish({ stepIndex: this._tutorialOffset[this.step], stepName: id });

        // close any popups
        await app.nav.closeLayer(NavLayer.popup);
    }

    // private: steps
    //-------------------------------------------------------------------------
    private async _clean() {
        let stepIndex = this._tutorialOffset[this.step];
        // mock garden
        const gardenState = {
            plots: {
                a: { level: 0, dirty: true },
                b: { level: 0, dirty: true },
                c: { level: 0, dirty: true },
                d: { level: 0, dirty: true },
                e: { level: 0, dirty: true },
            },
        } as Garden;

        if (!this._screen) {
            this._screen = (await app.nav.open('homeScreen', {
                gardenState,
                hideMenu: true,
                skipPointer: true,
            })) as HomeScreen;
            this._screen.playPlayerAnimation('sad', true);
        }

        trackTutorialStepStart({ stepIndex, stepName: `home_clean` });

        const bubble1 = await this._screen.spawnSpeechBubbleTutorial(`[tutorialDialog0]`);

        await sleep(1.25);
        await this._despawnView(bubble1);
        const bubble2 = await this._screen.spawnSpeechBubbleTutorial('[tutorialDialog1]');
        await sleep(1.25);
        await this._despawnView(bubble2);

        const plot = this._screen.getPlot('a');
        void plot.showFloatIcon('clean', true);

        // point to float icon in HomeScreen
        await new Promise<void>((resolve) => {
            plot.onPress = async () => resolve();
            plot.view.interactive = true;

            app.nav.open('tipScreen', {
                type: 'hand',
                motion: 'tap',
                allowInput: true,
                targets: [plot.view.getBounds()],
            });
        });
        plot.view.interactive = false;

        trackTutorialStepFinish({ stepIndex, stepName: `home_clean` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `clean_action` });

        app.nav.close('tipScreen');

        const updatedGarden = {
            plots: {
                a: { level: 0, dirty: false },
                b: { level: 0, dirty: true },
                c: { level: 0, dirty: true },
                d: { level: 0, dirty: true },
                e: { level: 0, dirty: true },
            },
        } as Garden;

        await this._screen.bouncePlayer('a');
        this._screen.playPlayerAnimation('cleaning').then(() => {
            this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'happy_loop' : 'happy_loop2', true);
        });

        await this._screen.updatePlots('a', updatedGarden, true);

        await sleep(0.7);
        this._screen.playPlayerAnimation('walk', true);
        const walkTime = 1.5;
        sleep(walkTime).then(() => this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true));
        const bubble3 = await this._screen.spawnSpeechBubbleTutorial('[tutorialDialog2]', walkTime);
        this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);
        await sleep(1.5);

        await this._despawnView(bubble3);
        trackTutorialStepFinish({ stepIndex, stepName: `clean_action` });
    }

    private async _name() {
        const stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `name` });
        if (!this._screen) {
            const gardenState = {
                plots: {
                    a: { level: 0, dirty: false },
                    b: { level: 0, dirty: true },
                    c: { level: 0, dirty: true },
                    d: { level: 0, dirty: true },
                    e: { level: 0, dirty: true },
                },
            } as Garden;
            this._screen = (await app.nav.open('homeScreen', {
                gardenState,
                hideMenu: true,
                skipPointer: true,
            })) as HomeScreen;
            this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);
        }

        const sequenceViews = await this._screen.spawnSpeechSequence(['[tutorialDialog3]', '[tutorialDialog4]']);
        await sleep(0.6);

        const namePopupId = 'namePopup';
        const namePromise = new NakedPromise<string>();

        const isEn = app.settings.language === 'en';
        const preFilledName = isEn ? garden.defaultNameEN : garden.defaultNameJA;

        (await app.nav.open(namePopupId, { onConfirm: namePromise, preFilledName })) as NamePopup;
        const name = await namePromise;
        this._despawnViews(sequenceViews);
        // render new name
        this._screen.setSetName(name, true);

        const popupPromise = app.nav.close(namePopupId);

        //make sure keyboard and popup has time to close and then force a resize to fix layout edge case on android devices
        popupPromise.then(() => sleep(0.4)).then(() => this._screen.forcedResize());

        this._screen.playPlayerAnimation('excited', true);
        const bubble2 = await this._screen.spawnSpeechBubbleTutorial(`[tutorialDialog5|${name}]`);
        // store name before exiting the step
        await app.server.invoke.playerSetName({ name });
        await sleep(1.5);
        await this._despawnView(bubble2);

        this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);
        trackTutorialStepFinish({ stepIndex, stepName: `name` });
    }

    private async _plant() {
        let stepIndex = this._tutorialOffset[this.step];
        const gardenState = {
            plots: {
                a: { level: 0, dirty: false },
                b: { level: 0, dirty: true },
                c: { level: 0, dirty: true },
                d: { level: 0, dirty: true },
                e: { level: 0, dirty: true },
            },
        } as Garden;
        if (!this._screen) {
            this._screen = (await app.nav.open('homeScreen', {
                gardenState,
                hideMenu: true,
                skipPointer: true,
            })) as HomeScreen;
            this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);
        }

        trackTutorialStepStart({ stepIndex, stepName: `plant_1` });

        const sequenceViews = await this._screen.spawnSpeechSequence(['[tutorialDialog6]', '[tutorialDialog7]']);
        await sleep(0.6);

        const plot = this._screen.getPlot('a');
        void plot.showFloatIcon('plant', true);

        await new Promise<void>((resolve) => {
            plot.onPress = async () => resolve();
            plot.view.interactive = true;

            app.nav.open('tipScreen', {
                type: 'hand',
                motion: 'tap',
                allowInput: true,
                targets: [plot.view.getBounds()],
            });
        });
        plot.view.interactive = false;

        this._despawnViews(sequenceViews);

        trackTutorialStepFinish({ stepIndex, stepName: `plant_1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `plant_2` });

        app.nav.close('tipScreen');

        const updatedGarden = {
            plots: {
                a: { level: 1, dirty: false },
                b: { level: 0, dirty: true },
                c: { level: 0, dirty: true },
                d: { level: 0, dirty: true },
                e: { level: 0, dirty: true },
            },
        } as Garden;

        await this._screen.bouncePlayer('a', 30);
        this._screen.playPlayerAnimation('planting').then(() => {
            this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'happy_loop' : 'happy_loop2', true);
        });

        await this._screen.updatePlots('a', updatedGarden, true);
        await sleep(1);
        this._screen.playPlayerAnimation('walk', true);

        const walkTime = 1.5;
        sleep(walkTime).then(() => this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true));
        const bubble1 = await this._screen.spawnSpeechBubbleTutorial(`[tutorialDialog8]`, walkTime);

        await sleep(1.25);
        await this._despawnView(bubble1);

        // update real state in the end
        await app.server.invoke.tutorialCleanA();
        trackTutorialStepFinish({ stepIndex, stepName: `plant_2` });
    }

    private async _end() {
        const stepIndex = this._tutorialOffset[this.step];
        if (!this._screen) {
            const gardenState = {
                plots: {
                    a: { level: 1, dirty: false },
                    b: { level: 0, dirty: true },
                    c: { level: 0, dirty: true },
                    d: { level: 0, dirty: true },
                    e: { level: 0, dirty: true },
                },
            } as Garden;
            this._screen = (await app.nav.open('homeScreen', {
                gardenState,
                hideMenu: true,
                skipPointer: true,
            })) as HomeScreen;
            this._screen.playPlayerAnimation(Math.random() < 0.5 ? 'idle' : 'idle2', true);
        }

        trackTutorialStepStart({ stepIndex, stepName: `closing_thoughts` });
        // bubble with tap block
        const finalPromise = new NakedPromise();
        await this._screen.spawnBubbleTap('[tutorialDialog9]', finalPromise);
        await finalPromise;

        // update real state in the end
        await app.server.invoke.tutorialUpgradeA();
        trackTutorialStepFinish({ stepIndex, stepName: `closing_thoughts` });
    }

    // helper
    private async _despawnView(view: Container) {
        await view.animate().add(view, { alpha: 0 }, 0.3, tween.pow2Out);
        view.removeSelf();
    }

    private _despawnViews(views: Container[]) {
        for (const view of views) {
            view.animate()
                .add(view, { alpha: 0 }, 0.3, tween.pow2Out)
                .then(() => view.removeSelf());
        }
    }
}
