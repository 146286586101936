import { IPointData } from 'pixi.js';

import { floatRandom } from '../../replicant/util/mathTools';

// types
//-----------------------------------------------------------------------------
export type DirectionType = { x?: number; y?: number };
export type PositionType = { x?: number; y?: number } & IPointData;
export type XyType = DirectionType | PositionType;
export type SizeType = { width: number; height: number };
export type BoundsType = PositionType & SizeType;
export type RegionType = {
    left: number;
    right: number;
    top: number;
    bottom: number;
};
export type RangeType = [number, number];
export type DynamicNumber = number | RangeType | (() => number);
export type DynamicXy = XyType | [XyType, XyType] | (() => XyType);

// functions
//-----------------------------------------------------------------------------
export type BasicHandler = () => any;
export type BasicAsyncHandler = () => Promise<any>;
export type TweenFunction = (time: number) => number;

// defaults
//-----------------------------------------------------------------------------
export const positionZero = { x: 0, y: 0 };
export const positionOne = { x: 1, y: 1 };
export const sizeOne = { width: 1, height: 1 };
export const regionZero: RegionType = { left: 0, right: 0, top: 0, bottom: 0 };

// helpers
//-----------------------------------------------------------------------------
export function getBoundsCenter(bounds: BoundsType): PositionType {
    return {
        x: bounds.x + bounds.width / 2,
        y: bounds.y + bounds.height / 2,
    };
}

export function importDynamicNumber(n: DynamicNumber): number {
    // range
    if (Array.isArray(n)) return floatRandom(n[0], n[1]);
    // function
    if (typeof n === 'function') return n();
    return n;
}

export function importDynamicXy(p: DynamicXy, proportional: boolean): XyType {
    // range
    if (Array.isArray(p)) {
        const r = Math.random();
        return {
            x: floatRandom(p[0].x, p[1].x, () => r),
            y: floatRandom(p[0].y, p[1].y, proportional ? () => r : Math.random),
        };
        // position
    }
    // function
    if (typeof p === 'function') return p();
    return p;
}
