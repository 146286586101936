import { Plugin } from '@play-co/astro';

import { addSentryContext } from '../../app/lib/sentry';
import app from '../../index.entry';
import { coreTech } from './CoreTech';

export class CoreTechPlugin extends Plugin {
    public async prepare(): Promise<void> {
        await coreTech.loadPromise;
        addSentryContext();
        app.server = coreTech.replicant;
        await coreTech.start();
    }
}
