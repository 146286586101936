import { Sprite, Texture } from 'pixi.js';

// types
//-----------------------------------------------------------------------------
export type PointerType = 'hand';
export type PointerOptions = {
    type: PointerType;
};

// constants
//-----------------------------------------------------------------------------
const pointers = {
    hand: {
        image: 'hand.png',
        pivot: { x: 0, y: 0 },
    },
};

export class Pointer extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(options: PointerOptions) {
        const pointer = pointers[options.type];
        const pivot = pointer.pivot;

        // init base
        super(Texture.from(pointer.image));
        this.scale = { x: 0.5, y: 0.5 };
        this.pivot.set(pivot.x * this.width, pivot.y * this.height);
    }

    static assets(): string[] {
        return Object.values(pointers).map((data) => data.image);
    }
}
