import { Layout } from '@pixi/layout';
import { Container, Graphics, ITextStyle } from 'pixi.js';

import app from '../../../index.entry';
import { BasicHandler } from '../../../lib/defs/types';
import { Line } from '../../../lib/ui/widgets/Line';
import { TextSliceCheckbox } from '../../lib/ui/buttons/TextSliceCheckbox';
import { ScrollBox } from '../../lib/ui/containers/ScrollBox';
import { BasicPopup } from '../../lib/ui/popups/BasicPopup';
import { BasicText } from '../../lib/ui/text/BasicText';

// types
//-----------------------------------------------------------------------------
export interface CheatMenuApi {
    close: () => void;
    show: () => void;
    hide: () => void;
}

export type ButtonCheatProp = {
    type: 'button';
    label: string;
    selected?: () => boolean;
    onPress: (api: CheatMenuApi) => Promise<void>;
};
export type LabelCheatProp = {
    type: 'label';
    label: string;
};
export type NumberCheatProp = {
    type: 'number';
    label: string;
    value: () => number;
    min: number;
    max: number;
    increment?: number;
    onUpdate: (value: number, api: CheatMenuApi) => void;
};
// TODO: add number cheat prop
export type CheatProp = ButtonCheatProp | LabelCheatProp;
export type CheatSection = CheatProp[][];
export type CheatLayout = CheatSection[];

export type CheatMenuOptions = {
    title: string;
    layout: CheatLayout;
    onClose?: BasicHandler;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    buttonGrey: 'button.grey.png',
    buttonRed: 'button.red.png',
    buttonBlue: 'button.blue.png',
};

const styles = {
    width: 640,
    height: 720,
    spacing: 5,
    prop: {
        height: 40,
    },
};

const textStyle: Partial<ITextStyle> = {
    align: 'center',
    fill: '#fff',
    fontFamily: 'noto',
    lineJoin: 'round',
};

/*
    cheat menu
*/
export class CheatMenu extends BasicPopup {
    // fields
    //-------------------------------------------------------------------------
    // events
    // scene

    // components
    private _api: CheatMenuApi;
    private _scroller: ScrollBox;
    private readonly _propMap = {
        button: this._createButtonProp,
        label: this._createLabelProp,
        // TODO: add number prop
        // number: this._createNumberProp,
    };

    // properties
    //-------------------------------------------------------------------------

    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets(Object.values(manifest))];
    }

    // @ts-ignore
    public override async spawning(options: CheatMenuOptions) {
        super.spawning({
            header: options.title,
            width: styles.width,
            height: styles.height,
            ...options,
        });

        // init api
        this._initApi(options);

        // spawn body
        this._spawnBody(options);
    }

    // private: init
    //-------------------------------------------------------------------------
    private _initApi(options: CheatMenuOptions) {
        this._api = {
            close: async () => {
                options.onClose();
            },
            show: () => {},
            hide: () => {},
        };
    }

    private _spawnBody(options: CheatMenuOptions) {
        const border = 40;
        const width = styles.width - border * 2;

        // create scroller
        const scroller = (this._scroller = new ScrollBox({
            direction: 'down',
            width,
            height: styles.height - border * 2 - 80,
        }));

        this.main.addContent({
            scroller: {
                content: scroller,
                styles: {
                    position: 'bottomCenter',
                },
            },
        });

        // create layout
        const layout = this._createLayout(options.layout, width);

        // spawn
        scroller.content.addChild(layout);
    }

    // private: factory
    //-------------------------------------------------------------------------
    // layout
    private _createLayout(cheatLayout: CheatLayout, width: number): Layout {
        // create container
        const layout = new Layout({});
        layout.setStyles({
            height: 'auto',
            width: 'auto',
        });

        // for each layout section
        cheatLayout.forEach((section, i) => {
            // spawn separator
            if (i !== 0) {
                layout.addContent({
                    [`separator${i}`]: {
                        content: this._createSeparator(width),
                        styles: {
                            display: 'inlineblock',
                            // XXX: marginTop and marginBottom are workaround for creating space
                            // between separator and section, see ab test cheat menu for example
                            marginTop: 69,
                            marginBottom: 69,
                        },
                    },
                });
            }

            // spawn section
            layout.addContent({
                [`section${i}`]: {
                    content: this._createSection(section, width),
                    styles: {
                        display: 'inlineblock',
                    },
                },
            });
        });

        // XXX: have to manually set visible to true, otherwise it won't show up
        layout.visible = true;
        return layout;
    }

    private _createSection(section: CheatSection, width: number): Layout {
        // create container
        const layout = new Layout({});
        layout.setStyles({
            height: 'auto',
            width: 'auto',
            display: 'inlineblock',
        });

        // spawn prop rows
        section.forEach((row, i) => {
            layout.addContent({
                [`row${i}`]: {
                    content: this._createPropRow(row, width),
                    styles: {
                        height: 'auto',
                        width: 'auto',
                        display: 'inlineblock',
                    },
                },
            });
        });

        // XXX: have to manually set visible to true, otherwise it won't show up
        layout.visible = true;
        return layout;
    }

    private _createPropRow(row: CheatProp[], width: number): Layout {
        const propWidth = (width - styles.spacing * (row.length - 1)) / row.length;

        // create container
        const layout = new Layout({});
        layout.setStyles({
            height: 'auto',
            width: 'auto',
        });

        // spawn props
        row.forEach((prop, i) => {
            layout.addContent({
                [`prop${i}`]: {
                    content: this._createProp(prop, propWidth),
                    styles: {
                        height: 'auto',
                        width: 'auto',
                    },
                },
            });
        });

        // XXX: have to manually set visible to true, otherwise it won't show up
        layout.visible = true;
        return layout;
    }

    // layout.props
    private _createProp(prop: CheatProp, width: number): Container {
        return this._propMap[prop.type].call(this, prop, width);
    }

    private _createButtonProp(prop: ButtonCheatProp, width: number): Container {
        const button = new TextSliceCheckbox({
            text: prop.label,
            animate: true,
            offsetY: -3,
            selected: prop.selected ? prop.selected() : true,
            slice: {
                sliceUp: manifest.buttonGrey,
                sliceDown: manifest.buttonBlue,
                width,
                height: 69,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                fill: 'fff4e6',
                strokeThickness: 2,
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
            },
        });
        button.onPress = () => prop.onPress(this._api);
        return button;
    }

    private _createLabelProp(prop: LabelCheatProp, width: number): Container {
        const height = styles.prop.height;
        return new BasicText({
            text: prop.label,
            style: {
                ...textStyle,
                fontSize: Math.ceil(height * 0.9),
                strokeThickness: Math.ceil(height * 0.12),
                lineJoin: 'round',
            },
        });
    }

    // etc
    private _createSeparator(width: number): Graphics {
        return new Line({
            from: { x: 0, y: 0 },
            to: { x: width, y: 0 },
            size: 5,
            color: 0x00d0ff,
        });
    }
}
