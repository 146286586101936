import { Container } from 'pixi.js';

import { BasicHandler } from '../../../lib/defs/types';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { ScrollBox } from '../../lib/ui/containers/ScrollBox';
import { BasicPopup } from '../../lib/ui/popups/BasicPopup';
import { BasicText } from '../../lib/ui/text/BasicText';

// types
//-----------------------------------------------------------------------------
export type LegalPopupOptions = {
    title: string;
    message: string;
    onOk: BasicHandler;
};

/*
    popup for legalese
*/
export class LegalPopup extends BasicPopup {
    // fields
    //-------------------------------------------------------------------------
    private _scroller: ScrollBox;

    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload()];
    }

    // @ts-ignore
    public override async spawning(options: LegalPopupOptions) {
        super.spawning({
            header: options.title,
            width: 600,
            height: 680,
            ...options,
        });

        // spawn
        this._spawnBody(options);
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnBody(options: LegalPopupOptions) {
        const width = 520;
        const height = 430;

        // create scroller
        const scroller = (this._scroller = new ScrollBox({
            direction: 'down',
            width,
            height,
        }));
        scroller.y = 40;
        scroller.x = 25; // better centering with offset
        this.main.addChild(scroller);

        // message
        this._spawnMessage(scroller.content, options.message, width);
    }

    private _spawnMessage(container: Container, text: string, width: number) {
        let y = 0;
        const fontSize = 18;

        // get locale text
        text = textLocaleFormat(text);

        // text can be huge so gotta be broken up since BasicText cant handle it
        const subtexts = text.split('\n');
        for (const subtext of subtexts) {
            // if not empty
            if (subtext) {
                // spawn subtext
                const widget = new BasicText({
                    text: subtext,
                    style: {
                        breakWords: true,
                        fill: '#fff',
                        fontSize,
                        leading: 0,
                        wordWrap: true,
                        wordWrapWidth: width,
                    },
                });
                widget.y = y;
                container.addChild(widget);

                // increment by subtext height
                y += widget.height;
            }

            // increment newline height
            y += fontSize * 0.5;
        }
    }
}
