import { Layout } from '@pixi/layout';
import { Container } from 'pixi.js';

import { SizeType } from '../../../lib/defs/types';
import { IScreen } from '../../../plugins/nav/IScreen';
import { pixiConfig } from '../../defs/config';

/*
    base layout screen. normalizes screen size.
*/
export class LayoutScreen2 implements IScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _root: Container;
    private _base: Layout;

    // properties
    //-------------------------------------------------------------------------
    public get root(): Container {
        return this._root;
    }

    public get base(): Layout {
        return this._base;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // root
        this._root = new Container();

        // base
        this._base = this._root.addChild(
            new Layout({
                id: 'root',
                styles: {
                    width: '100%',
                    height: '100%',
                },
            }),
        );
    }

    // api
    //-------------------------------------------------------------------------
    protected empty() {
        // remove all children
        this.base.removeChildren();

        // FIXME workaround to remove all children
        for (const id of this.base.content.children.keys()) {
            this.base.removeChildByID(id);
        }
    }

    // impl
    //-------------------------------------------------------------------------
    public resized(size: SizeType): void {
        const appSize = pixiConfig.size;

        /*
        //TODO: screen rotation stuff for IOS. WIP. to be moved to seperate plugin with android stuff
        if (size.height < size.width) {
            //this._root.pivot.set(size.width, size.height);
            this._root.rotation = Math.PI / 2;
            this._root.x = size.width;

            // swap width, height
            const t = size.width;
            size.width = size.height;
            size.height = t;

            if (size.width > appSize.width) {
                this._root.scale.set(size.width / appSize.width);
                size.height = Math.round(size.height * appSize.width / size.width);
                size.width = appSize.width;
            }
        } else {
            this._root.scale.set(1);
            this._root.x = 0;
            this._root.rotation = 0;
        }
 
        app.debug(window.orientation);
        //window.screen.orientation || window.screen.mozOrientation || window.screen.msOrientation
        //console.log(window.screen.orientation);
        //*/

        // scale to fit minimum app height
        if (size.height < appSize.height) {
            this._root.scale.set(size.height / appSize.height);
            size.width = Math.round((size.width * appSize.height) / size.height);
            size.height = appSize.height;
        } else {
            this._root.scale.set(1);
        }

        // layout resize
        this._base.resize(size.width, size.height);
    }
}
