import { Sprite } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { uiAlignCenter, uiAlignCenterX, uiAlignCenterY, uiCreateQuad } from '../../../../lib/pixi/uiTools';
import { Garden, PlotId } from '../../../../replicant/defs/garden';
import { plantItemPropsMap } from '../../../../replicant/defs/items';
import { TextImageButton } from '../buttons/TextImageButton';
import { ScrollBox } from '../containers/ScrollBox';
import { BasicPopup } from './BasicPopup';

const HEIGHT = 800;

// types
//-----------------------------------------------------------------------------
export type PlantPopupOptions = {
    onClose: BasicAsyncHandler;
    gardenState: Garden;
    locked?: boolean;
};

const manifest = {
    itemFrame: 'frame.slot.png',
    headerContainer: 'frame.slot.brown.png',
    title: 'icon.garden.title.png',
    greenButton: 'button.green2.png',
};

const dirtyPlotManifest = {
    a: 'item.ruined.area.a.png',
    b: 'item.ruined.area.b.png',
    c: 'item.ruined.area.c.png',
    d: 'item.ruined.area.d.png',
    e: 'item.ruined.area.e.png',
};

/*
    General garden plant popup
*/
export class PlantPopup extends BasicPopup {
    // injected
    onItemPress: (id: PlotId) => void;

    private _itemButtons: TextImageButton[];

    public get itemButtons() {
        return this._itemButtons;
    }

    public override preload() {
        return [
            ...super.preload(),
            ...app.resource.loadAssets([
                ...Object.values(manifest),
                ...Object.values(dirtyPlotManifest),
                ...Object.keys(plantItemPropsMap).map((key: PlotId) => plantItemPropsMap[key].icon),
            ]),
        ];
    }

    // @ts-ignore
    public override async spawning(options: PlantPopupOptions) {
        super.spawning({
            width: 620,
            height: HEIGHT,
            underlay: 0.7,
            ...options,
        });

        // clean out old references or create new one
        this._itemButtons = [];

        const scroll = this._createItemScroll(options.gardenState);

        // dont allow scroll input if we lock it
        scroll.input = !options?.locked;

        const header = Sprite.from(manifest.headerContainer);
        const title = Sprite.from(manifest.title);

        header.addChild(title);
        uiAlignCenter(header, title);

        // spawn
        this.main.addContent({
            header: {
                content: header,
                styles: {
                    position: 'topCenter',
                    marginTop: -70,
                },
            },
            scroll: {
                content: scroll,
                styles: {
                    position: 'topCenter',
                    marginTop: 26,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }

    private _createItemScroll(gardenState: Garden) {
        const itemScroll = new ScrollBox({
            direction: 'down',
            width: this.base.width - 60,
            height: this.base.height - (150 + 30),
        });

        let y = 40;
        for (const id in gardenState.plots) {
            const plotId = id as PlotId;
            const plot = gardenState.plots[plotId];
            const icon = plot.dirty
                ? Sprite.from(dirtyPlotManifest[plotId])
                : Sprite.from(plantItemPropsMap[plotId].icon);

            const plantItem = Sprite.from(manifest.itemFrame);
            const plantButton = new TextImageButton({
                text: plot.dirty ? '[buttonClean]' : plot.level === 0 ? '[buttonPlant]' : '[buttonGrow]',
                image: manifest.greenButton,
                y: -4,
                slice: {
                    width: 270,
                    height: 107,
                    left: 45,
                    top: 0,
                    right: 45,
                    bottom: 0,
                },
                style: {
                    dropShadow: true,
                    dropShadowAngle: Math.PI / 2,
                    dropShadowDistance: 3,
                    dropShadowAlpha: 0.7,
                    dropShadowBlur: 5,
                    fill: '#fff',
                    fontSize: 36,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                },
            });

            plantItem.addChild(icon, plantButton);
            uiAlignCenterY(plantItem, icon);
            icon.scale.set(0.66);
            icon.position.set(30, -10);
            uiAlignCenterY(plantItem, plantButton);
            plantButton.x = 235;

            plantButton.onPress = async () => {
                this.onItemPress?.(plotId);
            };

            plantItem.y = y;
            y += plantItem.height + 10;
            uiAlignCenterX(itemScroll, plantItem);

            itemScroll.content.addChild(plantItem);
            this._itemButtons.push(plantButton);
        }
        // padding hack at the bottiom for full scroll view
        const bottomPadding = uiCreateQuad(0x0, 0.001, this.base.width - 70, 30);
        bottomPadding.y = y;
        itemScroll.content.addChild(bottomPadding);

        return itemScroll;
    }
}
