import { SB } from '@play-co/replicant';

import { coreState } from './defs/core';
import { firstSessionState } from './defs/firstSession';
import { gardenState } from './defs/garden';
import { playerState } from './defs/player';
import { settingsState } from './defs/settings';
import { tutorialState } from './defs/tutorial';

// combined state
//-----------------------------------------------------------------------------
export const stateSchema = SB.object({
    ...coreState,
    ...gardenState,
    ...playerState,
    ...settingsState,
    ...firstSessionState,
    ...tutorialState,
});
