import { PlotId } from './garden';

type ItemProps = { name: string; icon: string; id: string };
type V2ItemProps = Pick<ItemProps, 'id'>;
export type PlantId =
    | '04' // Background tree
    | (typeof plantItemPropsMap)[PlotId]['id'] // V1 plot plants
    | (typeof plantItemV2PropsMap)[PlotId]['id']; // V2 plot plants;

// level 1 garden map, refactor to array for each level if we need more
export const plantItemPropsMap = {
    a: {
        name: '[itemPlant1]',
        icon: `item.flower.small.png`,
        id: '01',
    },
    b: {
        name: '[itemPlant2]',
        icon: `item.flower.large.png`,
        id: '02',
    },
    c: {
        name: '[itemPlant3]',
        icon: 'item.plant.middle.png',
        id: '03',
    },
    d: {
        name: '[itemPlant5]',
        icon: `icon.fence.png`,
        id: '05',
    },
    e: {
        name: '[itemPlant6]',
        icon: `item.little.garden.png`,
        id: '06',
    },
} as const satisfies Record<PlotId, ItemProps>;

export const plantItemV2PropsMap = {
    a: {
        id: '_01',
    },
    b: {
        id: '02',
    },
    c: {
        id: '03',
    },
    d: {
        id: '_08',
    },
    e: {
        id: '_07',
    },
} as const satisfies Record<PlotId, V2ItemProps>;
