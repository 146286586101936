import { Container, TextStyle } from 'pixi.js';

import app from '../../../index.entry';
import { BasicAsyncHandler, BasicHandler } from '../../../lib/defs/types';
import { uiAlignCenterX, uiCreateQuad } from '../../../lib/pixi/uiTools';
import { LanguageId } from '../../../replicant/defs/settings';
import { TextButton } from '../../lib/ui/buttons/TextButton';
import { TextSliceButton } from '../../lib/ui/buttons/TextSliceButton';
import { TextSliceCheckbox } from '../../lib/ui/buttons/TextSliceCheckbox';
import { BasicPopup } from '../../lib/ui/popups/BasicPopup';
import { BasicText } from '../../lib/ui/text/BasicText';

// types
//-----------------------------------------------------------------------------
export type SettingsPopupOptions = {
    bgm: boolean;
    sfx: boolean;
    language: LanguageId;
    onClose: BasicAsyncHandler;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    buttonGrey: 'button.grey.png',
    buttonRed: 'button.red.png',
    buttonBlue: 'button.blue.png',
};

/*
    settings popup
*/
export class SettingsPopup extends BasicPopup {
    // fields
    buttonEnableBGM: TextSliceCheckbox;
    buttonDisableBGM: TextSliceCheckbox;
    buttonEnableSFX: TextSliceCheckbox;
    buttonDisableSFX: TextSliceCheckbox;
    buttonJapanese: TextSliceCheckbox;
    buttonEnglish: TextSliceCheckbox;
    buttonTos: TextButton;
    buttonPrivacy: TextButton;
    buttonSupport: TextButton;
    //-------------------------------------------------------------------------
    // events
    onPrivacy: BasicAsyncHandler;
    onTos: BasicHandler;
    onEnglish: BasicAsyncHandler;
    onJapanese: BasicAsyncHandler;
    onEnableBGM: BasicAsyncHandler;
    onDisableBGM: BasicAsyncHandler;
    onEnableSFX: BasicAsyncHandler;
    onDisableSFX: BasicAsyncHandler;
    onContactSupport: BasicAsyncHandler;
    // scene

    // properties
    //-------------------------------------------------------------------------

    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets(Object.values(manifest))];
    }

    // @ts-ignore
    public override async spawning(options: SettingsPopupOptions) {
        super.spawning({
            header: '[settingsTitle]',
            width: 600,
            height: 700,
            ...options,
        });

        this._spawnRow('bgm');
        this._spawnRow('sfx');
        this._spawnRow('language');

        this._spawnLine();

        this._spawnTos();

        this._spawnContactSupport();

        this.initData(options);
    }

    private initData(opts: { bgm: boolean; sfx: boolean; language: LanguageId }) {
        const { bgm, sfx, language } = opts;
        // sync state
        this.buttonEnableBGM.selected = bgm;
        this.buttonDisableBGM.selected = !bgm;
        this.buttonEnableSFX.selected = sfx;
        this.buttonDisableSFX.selected = !sfx;
        this.buttonEnglish.selected = language === 'en';
        this.buttonJapanese.selected = language === 'ja';
    }

    private _spawnRow(type: 'bgm' | 'sfx' | 'language') {
        const sliceConst = {
            width: 150,
            height: 69,
            left: 45,
            top: 0,
            right: 45,
            bottom: 0,
        };
        const sliceBlue = {
            sliceUp: manifest.buttonGrey,
            sliceDown: manifest.buttonBlue,
            ...sliceConst,
        };

        const sliceRed = {
            sliceUp: manifest.buttonGrey,
            sliceDown: manifest.buttonRed,
            ...sliceConst,
        };

        const slice = {
            bgm: [sliceBlue, sliceRed],
            sfx: [sliceBlue, sliceRed],
            language: [sliceBlue, sliceBlue],
        };

        const label = {
            bgm: '[popupSettingsBGM]',
            sfx: '[popupSettingsSFX]',
            language: '[popupSettingLanguage]',
        };

        const rowOffset = {
            bgm: 10,
            sfx: 100,
            language: 240,
        };

        const buttonText = {
            bgm: ['[popupSettingsOn]', '[popupSettingsOff]'],
            sfx: ['[popupSettingsOn]', '[popupSettingsOff]'],
            language: ['[popupSettingsJapanese]', '[popupSettingsEnglish]'],
        };

        const container = new Container();
        const text = new BasicText({
            text: label[type],
            style: {
                align: 'left',
                fill: 'fff4e6',
                strokeThickness: 4,
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: false,
                fontStyle: 'normal',
            },
        }).props({ x: 35, y: 45 + rowOffset[type] });

        // button style
        const paddingX = 20;
        const style: Partial<TextStyle> = {
            fill: '#FFFFFF',
            strokeThickness: 4,
            fontSize: 22,
            fontWeight: 'bold',
            lineJoin: 'round',
            wordWrap: false,
            fontStyle: 'italic',
        };

        const buttonY = 70 + rowOffset[type];

        const leftButton = new TextSliceCheckbox({
            selected: false,
            text: buttonText[type][0],
            animate: true,
            offsetY: -3,
            paddingX,
            slice: slice[type][0],
            style,
        });
        const rightButton = new TextSliceCheckbox({
            text: buttonText[type][1],
            selected: false,
            animate: true,
            offsetY: -3,
            paddingX,
            slice: slice[type][1],
            style,
        });

        leftButton.props({
            x: 270,
            y: buttonY,
            pivot: { x: leftButton.width * 0.5, y: leftButton.height * 0.5 },
        });

        rightButton.props({
            x: 435,
            y: buttonY,
            pivot: { x: rightButton.width * 0.5, y: rightButton.height * 0.5 },
        });

        switch (type) {
            case 'bgm':
                this.buttonEnableBGM = leftButton;
                this.buttonDisableBGM = rightButton;
                break;
            case 'sfx':
                this.buttonEnableSFX = leftButton;
                this.buttonDisableSFX = rightButton;
                break;
            case 'language':
                this.buttonJapanese = leftButton;
                this.buttonEnglish = rightButton;
                break;
            default:
                throw new Error(`Invalid settings button type: ${type}`);
        }

        container.addChild(text, leftButton, rightButton);

        this.main.addChild(container);
    }

    private _spawnLine() {
        const lineMain = uiCreateQuad(0x304574, 1, 450, 3);
        uiAlignCenterX(this.main, lineMain);
        lineMain.y = 241;

        this.main.addChild(lineMain);
    }

    private _spawnTos() {
        this.buttonPrivacy = new TextButton({
            animate: true,
            text: '[privacyLink]',
            style: {
                fill: '#ADD8E6',
                align: 'center',
                strokeThickness: 2,
                fontSize: 24,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: false,
                fontStyle: 'normal',
            },
        });

        this.buttonPrivacy.props({
            x: 50,
            y: 500,
            pivot: { x: this.buttonPrivacy.width * 0.5, y: this.buttonPrivacy.height * 0.5 },
        });

        this.buttonTos = new TextButton({
            animate: true,
            text: '[tosTitle]',
            style: {
                fill: '#ADD8E6',
                align: 'center',
                strokeThickness: 2,
                fontSize: 24,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: false,
                fontStyle: 'normal',
            },
        });
        this.buttonTos.props({
            x: 50,
            y: 545,
            pivot: { x: this.buttonTos.width * 0.5, y: this.buttonTos.height * 0.5 },
        });

        uiAlignCenterX(this.main, this.buttonPrivacy);
        uiAlignCenterX(this.main, this.buttonTos);
        // Add the rest of the components
        this.main.addChild(this.buttonPrivacy, this.buttonTos);
    }

    private _spawnContactSupport() {
        const contactButtonWidth = 360;
        const contactButtonHeight = 69;
        this.buttonSupport = new TextSliceButton({
            text: '[faqPopupButton]',
            animate: true,
            offsetY: -3,
            slice: {
                sliceUp: manifest.buttonBlue,
                sliceDown: manifest.buttonBlue,
                width: contactButtonWidth,
                height: contactButtonHeight,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                fill: 'fff4e6',
                strokeThickness: 4,
                fontSize: 22,
                fontWeight: 'bold',
                fontStyle: 'italic',
                lineJoin: 'round',
                padding: 2,
            },
        }).props({
            x: 50,
            y: 415,
            pivot: { x: contactButtonWidth * 0.5, y: contactButtonHeight * 0.5 },
        });

        uiAlignCenterX(this.main, this.buttonSupport);
        this.main.addChild(this.buttonSupport);
    }
}
