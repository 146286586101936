import { LoaderDefs } from '../../plugins/nav/LoaderManager';
import { ScreenDefs } from '../../plugins/nav/NavPlugin';
import { AlphaTransition } from '../../plugins/nav/transitions/AlphaTransition';
import { AlertPopup } from '../lib/ui/popups/AlertPopup';
import { InfoPopup } from '../lib/ui/popups/InfoPopup';
import { PlantPopup } from '../lib/ui/popups/PlantPopup';
import { PromptPopup } from '../lib/ui/popups/PromptPopup';
import { CheatMenu } from '../main/cheat/CheatMenu';
import { CheatScreen } from '../main/cheat/CheatScreen';
import { HomeScreen } from '../main/garden/HomeScreen';
import { NamePopup } from '../main/garden/NamePopup';
import { MajorLoader } from '../main/loaders/MajorLoader';
import { SpinnerLoader } from '../main/loaders/SpinnerLoader';
import { SplashLoader } from '../main/loaders/SplashLoader';
import { LegalPopup } from '../main/popups/LegalPopup';
import { FaqPopup } from '../main/settings/FaqPopup';
import { SettingsPopup } from '../main/settings/SettingsPopup';
import { TipScreen } from '../main/tip/TipScreen';

// config
//-----------------------------------------------------------------------------
export enum NavLayer {
    main,
    menu,
    popup,
    tip,
    cheat,
    cheatPopup,
    count,
}

export const navLoaders: LoaderDefs = {
    splash: SplashLoader,
    boot: MajorLoader,
    default: SpinnerLoader,
};

// screens
//-----------------------------------------------------------------------------
export const navScreens: ScreenDefs = {
    alertPopup: {
        screen: AlertPopup,
        layer: NavLayer.popup,
    },
    cheatScreen: {
        screen: CheatScreen,
        layer: NavLayer.cheat,
    },
    cheatPopup: {
        screen: CheatMenu,
        layer: NavLayer.cheatPopup,
    },
    faqPopup: {
        screen: FaqPopup,
        layer: NavLayer.popup,
    },
    infoPopup: {
        screen: InfoPopup,
        layer: NavLayer.popup,
    },
    legalPopup: {
        screen: LegalPopup,
        layer: NavLayer.popup,
    },
    plantPopup: {
        screen: PlantPopup,
        layer: NavLayer.popup,
    },
    homeScreen: {
        screen: HomeScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    promptPopup: {
        screen: PromptPopup,
        layer: NavLayer.popup,
    },
    settingsPopup: {
        screen: SettingsPopup,
        layer: NavLayer.popup,
    },
    tipScreen: {
        screen: TipScreen,
        layer: NavLayer.tip,
    },
    namePopup: {
        screen: NamePopup,
        layer: NavLayer.popup,
    },
};
