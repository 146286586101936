import { Graphics } from 'pixi.js';

import { PositionType } from '../../defs/types';

// types
//-----------------------------------------------------------------------------
export type LineOptions = {
    from: PositionType;
    to: PositionType;
    size: number;
    color?: number;
    alpha?: number;
};

/*
    basic text button
*/
export class Line extends Graphics {
    // init
    //-------------------------------------------------------------------------
    constructor(options: LineOptions) {
        super();

        // draw a line
        this.lineStyle(options.size, options.color || 0, options.alpha || 1)
            .moveTo(options.from.x, options.from.y)
            .lineTo(options.to.x, options.to.y);
    }
}
