import { SB } from '@play-co/replicant';
import { IPointData } from 'pixi.js';

import { PlantId } from './items';

export type Garden = SB.ExtractType<typeof gardenSchema>;
export type PlotId = keyof Garden['plots'];
export type PathId = keyof typeof pathPositions;
export type Plot = SB.ExtractType<typeof plotSchema>;

const defaultNameEN = 'Curlin';
const defaultNameJA = 'くるりん';

// positions, only for first garden, refactor to array for each level if we need more
// y from the bottom of the screen
const plotPositions: Record<PlotId, { x: number; y: number; xOffset?: number }> = {
    a: { x: 270, y: 370, xOffset: 25 },
    b: { x: 660, y: 380 },
    c: { x: 190, y: 680, xOffset: 45 },
    d: { x: 660, y: 950 },
    e: { x: 250, y: 980, xOffset: 60 },
};

const pathPositions = {
    a: { x: plotPositions.a.x + 40, y: plotPositions.a.y + 100 },
    b: { x: plotPositions.b.x - 100, y: plotPositions.b.y + 200 },
    c: { x: plotPositions.c.x + 130, y: plotPositions.c.y + 200 },
    d: { x: plotPositions.d.x - 100, y: plotPositions.d.y },
    e: { x: plotPositions.e.x + 100, y: plotPositions.e.y },

    // more random positions in the middle
    f: { x: 400, y: 880 },
    g: { x: 380, y: 600 },
    h: { x: 440, y: 500 },
    k: { x: 330, y: 550 },
    l: { x: 390, y: 510 },
    m: { x: 260, y: 520 },
    n: { x: 440, y: 700 },
};

const npcPositions = {
    // 1 yellow/red fire
    // 2 white/lightblue
    // 3 yellow/brown fire
    npc01: { x: 80, y: 1000 },
    npc02: { x: 490, y: 110 },
    npc03: { x: 700, y: 550 },
};

// pre-planted
const plantPositions: Partial<Record<PlantId, IPointData[]>> = {
    // '1': [
    //     { x: 610, y: 610 },
    //     { x: 200, y: 1100 },
    //     { x: 270, y: 1120 },
    //     { x: 570, y: 1100 },
    //     { x: 500, y: 1120 },
    // ],
    // '2': [
    //     { x: 40, y: 20 },
    //     { x: 770, y: 250 },
    //     { x: 810, y: 940 },
    //     { x: 35, y: 1100 },
    //     { x: 350, y: 1200 },
    //     { x: 5, y: 320 },
    // ],
    // '3': [
    //     { x: 80, y: 400 },
    //     { x: 650, y: 650 },
    //     { x: 800, y: 180 },
    // ],
    '04': [
        { x: 80, y: -100 },
        { x: 70, y: 70 },
        { x: -25, y: 450 },
        { x: 750, y: 650 },
        { x: -40, y: 630 },
        { x: 600, y: 1250 },
        { x: 780, y: 1200 },
        { x: 710, y: 1100 },
        { x: 80, y: 1180 },
        { x: 420, y: 1190 },
        { x: 300, y: 1220 },
        { x: 220, y: 1130 },
    ],
    // '5': [
    //     { x: 190, y: 60 },
    //     { x: 520, y: 1140 },
    // ],
    // '6': [{ x: 700, y: 220 }],
};

export default {
    defaultNameEN,
    defaultNameJA,
    maxPlantLevel: 1, // increase this to allow higher level plants
    npcPositions,
    pathPositions,
    plantPositions,
    plotPositions,
};

// ----- state -----
const plotSchema = SB.object({
    level: SB.int().min(0),
    dirty: SB.boolean().default(true), // dirty by default, extra flag instead of plant level in case we want to implement cleaning on a upgraded plant
});

const gardenSchema = SB.object({
    // uncomment if we want multiple gardens
    // level: SB.int(),
    plots: SB.object({
        a: plotSchema,
        b: plotSchema,
        c: plotSchema,
        d: plotSchema,
        e: plotSchema,
    }),
});

export const gardenState = {
    garden: gardenSchema,
};
