import { DisplayObject, Sprite } from 'pixi.js';

import app from '../../index.entry';
import { BasicAsyncHandler } from '../../lib/defs/types';
import { uiAlignCenter } from '../../lib/pixi/uiTools';
import { tween } from '../../lib/util/tweens';
import { getPlantItemProps } from '../../replicant/components/garden';
import garden, { Plot, PlotId } from '../../replicant/defs/garden';
import { assertNever } from '../../replicant/util';
import { Plant } from '../concept/Plant';
import { ImageButton } from '../lib/ui/buttons/ImageButton';

const manifest = {
    floatBase: 'icon.float.base.png',
    floatClean: 'icon.float.clean.png',
    floatPlant: 'icon.float.plant.png',
};

const dirtyPlotManifest = {
    a: 'item.ruined.area.a.png',
    b: 'item.ruined.area.b.png',
    c: 'item.ruined.area.c.png',
    d: 'item.ruined.area.d.png',
    e: 'item.ruined.area.e.png',
};

export class PlotComponent {
    static assets() {
        return [...Object.values(manifest), ...Object.values(dirtyPlotManifest)];
    }

    public get view(): DisplayObject {
        return this.plotButton;
    }

    private plotButton: ImageButton;
    private floatBase: Sprite;

    constructor(
        plotId: PlotId,
        plot: Plot,
        public onPress?: BasicAsyncHandler,
    ) {
        const baseButtonWidth = 160;
        // const baseButtonHeight = 330;
        const baseButtonHeight = 220;
        const plotButton = (this.plotButton = new ImageButton({
            // uncomment for debug
            // image: manifest.puzzle,
            slice: {
                width: baseButtonWidth,
                height: baseButtonHeight,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
        }));
        // uncomment for debug
        // plotButton.alpha = 0.4;
        if (plot.dirty) {
            const dirt = Sprite.from(dirtyPlotManifest[plotId]);
            uiAlignCenter(plotButton, dirt);

            plotButton.button.addChild(dirt);
            uiAlignCenter(plotButton.button, dirt, 0, 60);
        } else {
            if (plot.level > 0) {
                const id = getPlantItemProps(app.server.state)[plotId].id;
                const xOffset = garden.plotPositions[plotId]?.xOffset;
                const plant = new Plant({ id });
                plant.speed = 0.55;
                plant.start(true);
                plotButton.button.addChild(plant);
                uiAlignCenter(plotButton, plant, xOffset ?? 40, 90);
            }
        }

        plotButton.pivot.set(plotButton.width * 0.5, plotButton.height * 0.5);
        plotButton.onPress = () => this.onPress?.();
    }

    public async showFloatIcon(type: 'clean' | 'plant', skipAnimation = false) {
        if (this.floatBase) {
            if (!skipAnimation) {
                await this.floatBase.animate().add(this.floatBase, { alpha: 0 }, 0.2, tween.pow2In).promise();
            }
            this.floatBase.removeSelf();
        }

        switch (type) {
            case 'clean':
                this.floatBase = this.spawnFloatClean();
                break;
            case 'plant':
                this.floatBase = this.spawnFloatPlant();
                break;
            default:
                assertNever(type);
        }

        if (!skipAnimation) {
            this.floatBase.alpha = 0;
            await this.floatBase.animate().wait(0.4).add(this.floatBase, { alpha: 1 }, 0.4, tween.pow2In).promise();
        }
    }

    private spawnFloatClean() {
        const floatBase = Sprite.from(manifest.floatBase);
        const floatClean = Sprite.from(manifest.floatClean);
        floatBase.addChild(floatClean);
        uiAlignCenter(floatBase, floatClean, 0, -13);
        this.plotButton.addChild(floatBase);
        floatBase.x = 20;
        return floatBase;
    }

    private spawnFloatPlant() {
        const floatBase = Sprite.from(manifest.floatBase);
        const floatPlant = Sprite.from(manifest.floatPlant);
        floatBase.addChild(floatPlant);
        uiAlignCenter(floatBase, floatPlant, 0, -13);
        this.plotButton.button.addChild(floatBase);
        floatBase.x = 20;
        // if (plot.level > 0) {
        //     floatBase.y = -10;
        // }
        return floatBase;
    }
}
