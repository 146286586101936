import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import garden, { PlotId } from '../../replicant/defs/garden';
import { sleep } from '../../replicant/util/jsTools';
import { HomeScreen } from '../main/garden/HomeScreen';

type PlantFlowOptions = {
    screen: HomeScreen;
    plotId: PlotId;
};

export class PlantFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();
    private _options: PlantFlowOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: PlantFlowOptions) {
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionPlant();
        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _actionPlant() {
        const { screen, plotId } = this._options;
        if (app.server.state.garden.plots[plotId].level === garden.maxPlantLevel) {
            screen.showPlayPuzzle();
            this._actionComplete(true);
            return;
        }

        await app.server.invoke.upgradePlot({ plotId });

        const isPlanted = app.server.state.garden.plots[plotId].level > 0;

        await screen.bouncePlayer(plotId);
        screen.playPlayerAnimation(isPlanted ? 'planting' : 'cleaning').then(() => {
            screen.playPlayerAnimation(Math.random() < 0.5 ? 'happy_loop' : 'happy_loop2', true);
        });

        await screen.updatePlots(plotId, app.server.state.garden);

        await sleep(1.4);
        screen.isInteracting = false;
        screen.centerPlayerX();
        this._actionComplete(true);
    }
}
