import { SB } from '@play-co/replicant';

import { getCreativeText } from '../chatbot/chatbotTexts';
import {
    chatbotMessageTemplates,
    FirstSessionAssetKey,
    generateChatbotPayload,
    ReplicantAssetKey,
} from '../chatbot/messageTemplates';
import garden from '../defs/garden';
import { MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import { LanguageId } from '../defs/settings';
import { isAbTestInBucket } from '../util/replicantTools';
import { timeFromComponents } from '../util/timeTools';

// constants
//-----------------------------------------------------------------------------
const firstSession0 = 'first_session_0';
const firstSession1 = 'first_session_1';
const firstSession2 = 'first_session_2';

// actions
//-----------------------------------------------------------------------------
// scheduledActions
//-----------------------------------------------------------------------------
export const firstSessionScheduledActionsSchema = {
    // token cant ge generated by scheduled action, pass it in
    firstSession: SB.object({ id: SB.string(), token: SB.string().optional() }),
};

export const firstSessionScheduledActions = {
    firstSession: async (
        state: MutableState,
        options: { id: FirstSessionAssetKey; token?: string },
        api: ScheduledActionAPI,
    ) => {
        // any message sent, toggle flag to not re-schedule anything on next session
        if (!state.sentInitMessage) {
            state.sentInitMessage = true;
        }
        await sendFirstSessionOA(state, api, options.id, options?.token);
    },
};
// api
//-----------------------------------------------------------------------------

// events
//-----------------------------------------------------------------------------
export function onFirstSessionInit(api: SyncActionAPI, state: MutableState) {
    // reset energy full event
    api.scheduledActions.unschedule(firstSession0);
    api.scheduledActions.unschedule(firstSession1);
    api.scheduledActions.unschedule(firstSession2);
}

export function onfirstSessionExit(api: ScheduledActionAPI, state: State, token?: string) {
    // if 1st message is successfully sent, dont schedule anymore messages
    if (state.sentInitMessage) return;

    api.scheduledActions.schedule.firstSession({
        args: { id: 'firstSession0', token },
        notificationId: firstSession0,
        delayInMS: timeFromComponents({ minutes: 15 }),
    });

    api.scheduledActions.schedule.firstSession({
        args: { id: 'firstSession1', token },
        notificationId: firstSession1,
        delayInMS: timeFromComponents({ hours: 4 }),
    });
    api.scheduledActions.schedule.firstSession({
        args: { id: 'firstSession2', token },
        notificationId: firstSession2,
        delayInMS: timeFromComponents({ hours: 8 }),
    });
}

export async function sendFirstSessionOA(
    state: MutableState,
    api: ScheduledActionAPI | SyncActionAPI,
    id: FirstSessionAssetKey,
    token?: string,
) {
    const lang = state.language as LanguageId;
    // chatbot text and asset key shares id
    const creativeText = getCreativeText(lang, id, api.math.random);
    // Default aspectRatio 1:1
    const aspectRatio = '3:2';

    const isEn = lang === 'en';
    const preFilledName = isEn ? garden.defaultNameEN : garden.defaultNameJA;

    const imageKey: ReplicantAssetKey = isAbTestInBucket(state, '0001_FirstSessionImprovements', 'ver.2')
        ? `${id}_v2`
        : id;

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...(await generateChatbotPayload({ feature: 'first_session', api, tokenOverride: token })),
                $creativeAssetID: id,
            },
        }),
    );
}
