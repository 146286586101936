const PROD = 'https://liff.line.me/2001284055-W5G0dRZK';
const DEV = 'https://liff.line.me/2001284006-QyGq4zNJ';
const LOCAL = 'https://localhost:8052';

export function getGameUrl() {
    let url = LOCAL; // No stage -> local dev.
    if (process.env.STAGE === 'prod') url = PROD;
    if (process.env.STAGE === 'dev') url = DEV;

    return url;
}
