import { Plugin, PluginOptions } from '@play-co/astro';

import app from '../../index.entry';
import { NavEvent, ScreenId } from '../../plugins/nav/NavPlugin';
import { trackScreenClose, trackScreenOpen } from '../lib/analytics/core';

// types
//-----------------------------------------------------------------------------
export type AnalyticsServiceOptions = PluginOptions;

/*
    monitors various events and forwards to analytics
*/
export class AnalyticsService extends Plugin {
    // fields
    public sessionTaps = 0;
    //-------------------------------------------------------------------------
    // private _stateObserver = new UpdateObserver();

    // init
    //-------------------------------------------------------------------------
    public init(): void {
        // subscribe to pubsub
        app.nav.events.subscribe(this._onNavEvent.bind(this));
    }

    public async start() {
        // observe state to update properties
        this._startStateMonitoring();
    }

    // private: events
    //-------------------------------------------------------------------------
    // nav
    private _onNavEvent(event: NavEvent) {
        // handle screen open/close
        if (event.screenId) {
            if (event.id === 'spawned') this._onNavScreenOpen(event.screenId, event.options);
            else if (event.id === 'despawned') this._onNavScreenClose(event.screenId);
        }
    }

    private _onNavScreenOpen(id: ScreenId, options?: object) {
        trackScreenOpen({ id, ...this._interpretNavOptions(id, options) });
    }

    private _onNavScreenClose(id: ScreenId) {
        trackScreenClose({ id });
    }

    // private: monitoring
    //-------------------------------------------------------------------------
    private _startStateMonitoring() {
        // world energy
        // this._stateObserver.listen(
        //     () => app.game.player.worldEnergy,
        //     (realtimeWorldEnergy) => analytics.setUserProperties({ realtimeWorldEnergy }),
        // );
        // // team power
        // this._stateObserver.listen(
        //     () => app.game.player.teamPower,
        //     (realtimeTeamPower) => {
        //         analytics.setUserProperties({
        //             realtimeTeamPower,
        //             realtimeTeam: parseTeamSimple(),
        //         });
        //     },
        // );
        // // citadel
        // this._stateObserver.listen(
        //     () => app.game.player.citadelLevel,
        //     (citadelLevel) => analytics.setUserProperties({ realtimeCitadelLevel: citadelLevel + 1 }),
        // );
        // // start
        // this._stateObserver.start();
    }

    // private: nav options
    //-------------------------------------------------------------------------
    private _interpretNavOptions(id: ScreenId, options?: object) {
        switch (id) {
            // example
            // case 'battleTip': {
            //     const character = (options as CharacterInfoPopupOptions).character;
            //     return {
            //         name: character.id,
            //         type: character.properties.type,
            //     };
            // }
            default:
                return {};
        }
    }
}
