import { SB } from '@play-co/replicant';

// state
//-----------------------------------------------------------------------------
export const tutorialState = {
    tutorial: SB.object({
        // initial tutorial
        garden: SB.object({
            step: SB.int().default(0),
            complete: SB.boolean().default(false),
        }),
    }),
};
