import { action } from '@play-co/replicant';

import { getCleanPlotEvent, getUpdatePlotEvent } from '../analytics/garden';
import garden, { PlotId } from '../defs/garden';
import { plantItemPropsMap, plantItemV2PropsMap } from '../defs/items';
import { MutableState, State } from '../defs/replicant';
import { getAbTest, isAbTestInBucket } from '../util/replicantTools';

// actions
//-----------------------------------------------------------------------------
export const gardenActions = {
    upgradePlot: action((state: MutableState, args: { plotId: PlotId }, api) => {
        const { plotId } = args;
        // state.garden.plots.
        const plot = state.garden.plots[plotId];
        const isInV2Test = !!getAbTest(state, '0001_FirstSessionImprovements');
        if (plot.dirty) {
            // clean plot
            plot.dirty = false;
            if (isInV2Test) {
                api.sendAnalyticsEvents([
                    getCleanPlotEvent({
                        plot: plotId,
                    }),
                ]);
            }
        } else {
            // upgrade plot
            if (plot.level < garden.maxPlantLevel) {
                plot.level++;
                if (isInV2Test) {
                    api.sendAnalyticsEvents([
                        getUpdatePlotEvent({
                            plot: plotId,
                            level: plot.level,
                        }),
                    ]);
                }
            }
        }
    }),
};

// api
//-----------------------------------------------------------------------------
export function getPlantItemProps(state: State) {
    return isAbTestInBucket(state, '0001_FirstSessionImprovements', 'ver.2') ? plantItemV2PropsMap : plantItemPropsMap;
}
